<template>
  <WizardModal
    v-if="isShowLimitedCompanyWorkStationModal"
    :steps="tabListItems"
    :currentStep="getLimitedCompanyWorkStationModal"
    @on-back="onClickBackButton"
    @on-close="onHandleClickClose"
    :hideBackButton="
      getLimitedCompanyWorkStationModal >= 5 &&
      activeUserWorkstation.subscription
    "
  >
    <template #content>
      <main class="main tw-w-full tw-h-full tw-pt-2 rmd:tw-pt-4">
        <div class="custom__modal__content tw-h-full">
          <div
            class="custom__modal__content__wrapper !tw-h-full soletrader__content__wrapper tw-gap-4"
          >
            <div
              class="custom__modal__content__wrapper__top tw-h-full tw-flex tw-flex-col tw-justify-between rmd:tw-w-[50%]"
              v-if="currentSelectedTab?.name === 'Company Info'"
            >
              <div
                class="custom__modal__sub__content tw-flex tw-flex-col tw-gap-2 tw-w-full"
              >
                <label>Company Name</label>
                <v-form :rules="validation" ref="tradingNameRef">
                  <v-text-field
                    :rules="validation.tradingName"
                    density="compact"
                    placeholder="E.g. Sparks Building Limited"
                    v-model="tradingName"
                    variant="outlined"
                    class="c-input rounded-full"
                  ></v-text-field>
                </v-form>
              </div>
              <Button
                label="continue"
                class="button disable"
                @click="onClickContinue"
                :isActive="tradingName.length"
                :isLoading="isWorkStationCreated"
              />
            </div>
            <div
              class="custom__modal__content__wrapper__top tw-h-full tw-flex tw-flex-col tw-justify-between rmd:tw-w-[50%]"
              v-if="currentSelectedTab.name === 'profile'"
            >
              <div class="custom__modal__sub__content">
                <div
                  class="uploade-wrapper solo-trader-uploade-wrapper md:!tw-flex md:!tw-flex-col md:!tw-items-center md:!tw-justify-center md:!tw-mt-0 md:!tw-w-full"
                >
                  <div class="uploade-wrapper md:!tw-mb-0 md:!tw-mt-0">
                    <v-file-input
                      class="upload_file tw-rounded-full tw-overflow-hidden"
                      accept="image/png, image/jpeg"
                      append-inner-icon="mdi-image-plus"
                      variant="solo-filled"
                      @change="onFileChange"
                      @click:clear="clearImageUrl"
                      :class="{ 'added-img': imageUrl }"
                    ></v-file-input>
                    <v-img
                      class="uploaded-img"
                      :width="200"
                      aspect-ratio="16/9"
                      cover
                      :src="imageUrl"
                    ></v-img>
                    <Text
                      variant="p"
                      textAlign="center"
                      textColor="rgba(255, 0, 0,0.5)"
                      >{{ fileUploadErrorMsg }}</Text
                    ><br />
                    <Text
                      variant="span"
                      textAlign="center"
                      textColor="rgba(12, 15, 74, 0.5)"
                      >Accepted file formats: PNG, JPEG</Text
                    >
                  </div>
                </div>
              </div>
              <Button
                label="continue"
                @click="onClickContinueUploadImage"
                :isActive="imageUrl.length"
                :isLoading="isWorkStationCreated"
              />
            </div>

            <div
              class="custom__modal__inner__content"
              v-if="currentSelectedTab.name === 'subscription'"
            >
              <div v-if="!isShowAddMemberModel">
                <ul class="check-list">
                  <li class="check-list__item">
                    Build trust with a Verified iknowa Contractor Passport
                  </li>
                  <li class="check-list__item">
                    Enjoy 100MB free storage for documents and files
                  </li>
                  <li class="check-list__item">
                    Bid on unlimited new jobs & win client projects
                  </li>
                  <li class="check-list__item">
                    Cancel your plan anytime if you're not satisfied
                  </li>
                  <li class="check-list__item">
                    After setting up your workstation, you can add members and
                    then pay £9.99 per month/User
                  </li>
                </ul>

                <div class="plan-cards">
                  <v-card class="plan-card">
                    <div class="plan-card__tags">
                      <span class="plan-card__tag-item">YEARLY</span>
                      <span class="plan-card__tag-item-discount"
                        >20% Discount</span
                      >
                    </div>
                    <p class="plan-card__price">
                      <span>£7.99</span> per month/ £95.90 today
                    </p>
                    <v-btn
                      class="button button-orange w-full"
                      :loading="yearlyPlanLoading"
                      @click="onClickPaymentPlan(PaymentPlanType.YEARLY)"
                      >Choose YEARLY</v-btn
                    >
                  </v-card>
                  <v-card class="plan-card">
                    <div class="plan-card__tags">
                      <span class="plan-card__tag-item">MONTHLY</span>
                    </div>
                    <p class="plan-card__price"><span>£9.99</span> per month</p>
                    <v-btn
                      class="button button-orange w-full"
                      :loading="monthlyPlanLoading"
                      @click="onClickPaymentPlan(PaymentPlanType.MONTHLY)"
                      >choose MONTHLY
                    </v-btn>
                  </v-card>
                </div>
              </div>
            </div>
            <!-- Free plan -->
            <!-- <div
              v-if="currentSelectedTab.name === 'subscription'"
              class="custom__modal__inner__content tw-overflow-y-auto tw-px-2 tw-box-border tw-pb-2"
            >
              <ul class="check-list tw-gap-2">
                <li class="check-list__item">Access to exclusive features</li>
                <li class="check-list__item">Good user experience</li>
                <li class="check-list__item">Priority customer support</li>
                <li class="check-list__item">New features and updates</li>
              </ul>
              <v-btn
                class="button button-orange tw-w-auto"
                @click="onClickFreePlan"
                >Start My Free Trial</v-btn
              >
            </div> -->
            <!-- <div
              class="custom__modal__inner__content tw-overflow-y-auto"
              v-if="currentSelectedTab?.name === 'Address'"
            >
              <div class="modal__inner__body">
                <v-form
                  ref="addressFormRef"
                  :rules="addressRule"
                  @submit.prevent
                >
                  <div v-if="isShowAddressData">
                    <div class="custom__modal__sub__content">
                      <label>Address line</label>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        class="c-input rounded-full"
                        v-model="addressForm.address"
                        :rules="addressRule.address"
                        :disabled="!isChangeManualAddress"
                      ></v-text-field>
                    </div>
                    <div class="custom__modal__sub__content">
                      <label>City / Town</label>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        class="c-input rounded-full"
                        v-model="addressForm.place"
                        :rules="addressRule.place"
                        :disabled="!isChangeManualAddress"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="custom__modal__sub__content postcode__wrapper">
                    <label>Postcode</label>
                    <v-text-field
                      density="compact"
                      placeholder="e.g E1 1BA"
                      variant="outlined"
                      class="c-input rounded-full tw-mb-2"
                      v-model="addressForm.postCode"
                      :rules="addressRule.postCode"
                      :disabled="!isChangeManualAddress && isShowAddressData"
                    ></v-text-field>
                    <ul
                      v-if="
                        addressListFromPostcode?.length && !isShowAddressData
                      "
                      class="address__postcodes"
                    >
                      <li
                        v-for="{ DPA } in addressListFromPostcode"
                        :key="DPA?.UPRN"
                        :class="{ 'is-active': selectedUPRN === DPA?.UPRN }"
                        @click="onSelectedAddress(DPA)"
                      >
                        <div>{{ DPA?.ADDRESS }}</div>
                      </li>
                    </ul>
                  </div>
                  <span class="error_msg" v-if="errorMessagePostcode">{{
                    errorMessagePostcode
                  }}</span>
                  <v-btn
                    v-if="!isShowAddressData && !addressListFromPostcode.length"
                    class="button disabled"
                    :class="{ 'button-orange': isDisabledNextButton }"
                    :loading="isActiveNextButton"
                    :disabled="!isDisabledNextButton"
                    @click.enter="onClickFindAddress"
                    size="large"
                    block
                  >
                    {{ selectedDPA ? "continue" : "find address" }}
                  </v-btn>
                  <v-btn
                    v-if="isShowAddressData"
                    class="button disabled"
                    :class="{ 'button-orange': isDisabledNextButton }"
                    size="large"
                    block
                    :disabled="!isDisabledNextButton"
                    :loading="loadingContinue"
                    @click.enter="onClickAddressContinue"
                  >
                    CONTINUE
                  </v-btn>
                  <div v-if="!isShowAddressData" class="onboarding-not-service">
                    <p>
                      Cannot find address?
                      <span @click="showManualForm"
                        >Enter Manually <v-icon icon="mdi-chevron-right"
                      /></span>
                    </p>
                  </div>
                  <div v-if="isShowAddressData" class="onboarding-not-service">
                    <p>
                      Address Not Correct?
                      <span @click="changeAddress"
                        >Change Address <v-icon icon="mdi-chevron-right"
                      /></span>
                    </p>
                  </div>
                </v-form>
              </div>
            </div>
            <div
              class="sole-trader-data-wrapper rmd:tw-w-[50%]"
              v-if="currentSelectedTab.name === 'idCheck'"
            >
              <div class="your-img-wrap">
                <img
                  class="open-logo"
                  src="../../../../assets/icons/id-check.svg"
                />
              </div>
              <v-btn
                class="button button-orange w-full"
                v-if="isShowNextButton"
                @click="goToNextTab"
                >Next</v-btn
              >
              <a class="btn_link" @click="onClickIdVerification"
                >Start ID verification</a
              >
            </div> -->
            <div
              v-if="currentSelectedTab.name === 'termCondition'"
              class="tw-flex tw-flex-col tw-gap-4"
            >
              <TermsAndCondition />
              <div class="tw-flex tw-flex-col tw-gap-3">
                <!-- <Text class="rmd:!tw-text-lg" variant="h6"
                  >General Platform Usage</Text
                > -->
                <div>
                  <v-checkbox
                    v-model="acceptAggreement"
                    label="I accept & agree to the Terms of Service"
                  ></v-checkbox>
                  <Button
                    class="rmd:!tw-w-[400px]"
                    label="finish"
                    @click="goToNextTab"
                    :isActive="acceptAggreement"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </template>
  </WizardModal>
  <commonDialog
    v-model="isShowSuccessfullModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    persistent
    :className="['workstation_create_modal']"
  >
    <template v-slot:body>
      <div
        class="custom__modal__heading tw-flex tw-flex-col tw-justify-center tw-items-center"
      >
        <div>
          <div class="header-title">
            Great job,
            <span class="tw-ml-2"> {{ activeUserWorkstation?.name }}.</span>
          </div>
          <div class="header-sub-title">
            You have successfully created your Trade Workstation.
          </div>
        </div>

        <div class="custom__modal__img">
          <img
            src="../../../../assets/images/ws-creation-success-image.png"
            alt="Create Workstation"
          />
        </div>
        <p class="description explore__desc">
          Time to take your business sky high and supercharge your skills! Start
          by completing your Trade Passport.
        </p>
      </div>
      <v-btn
        class="button button-orange !tw-mt-[16px] rmd:!tw-mt-[24px]"
        size="large"
        block
        @click="onClickLimitedCompanyDashboard"
      >
        Explore 🚀
      </v-btn>
    </template>
  </commonDialog>
  <PaymentStatusModal
    v-if="
      !isShowLimitedCompanyWorkStationModal &&
      !isShowKYBInfoModal &&
      !isShowSuccessfullModal
    "
    :onContinue="onClickPaymentStatusContinue"
    :paymentStatus="paymentStatus"
    :planType="planType"
  />
  <KYCStatusModal
    v-if="isShowKYBInfoModal"
    :onContinue="onClickTermAndConditionNext"
    kycStatus="KYBINFO"
  />
</template>

<style lang="scss" scoped>
@import "@/modules/workstation/soletrader/styles/SoleTraderWorkStationComponent.scss";
@import "@/modules/workstation/limitedcompany/styles/limitedcompany.scss";
</style>

<script lang="ts">
import { computed, onBeforeMount, onMounted, ref, watch } from "vue";
import {
  VUE_APP_OS_API,
  VUE_APP_OS_POSTCODE_URL,
  VUE_APP_STRIPE_PUBLISHABLE_KEY,
} from "@/config";
import { useStore } from "vuex";
import $axios from "@/core/utils/axios-api-config";
import { USER_STORE } from "@/store/modules/user";
import { cloneObject } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  BusinessDetailTypeEnum,
  PaymentPlanType,
} from "@/core/enums/RolesEnum";
import PaymentStatusModal from "@/core/components/PaymentStatusModal.vue";
import KYCStatusModal from "@/core/components/KYCStatusModal.vue";
import axios from "axios";
import CommonDialog from "@/core/components/CommonDialog.vue";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CheckBlue from "@/core/components/icons/CheckBlue.vue";

import WizardModal from "@/core/components/modals/WizardModal.vue";
import TermsAndCondition from "@/core/components/common/TermsAndCondition.vue";
import userService from "@/core/services/user.service";

export default {
  components: {
    PaymentStatusModal,
    KYCStatusModal,
    CommonDialog,
    Button,
    Text,
    // CheckBlue,
    WizardModal,
    TermsAndCondition,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const isShowLimitedCompanyWorkStationModal = ref(true);
    const isShowKYBInfoModal = ref(false);
    const checkoutRef = ref(null);
    const tradingName = ref("");
    const imageUrl = ref("");
    const imageData = ref("");
    const priceValue = ref("");
    const planType = ref() as any;
    const planTypeName = ref() as any;
    const totalMember = ref(1);
    const membershipPrice = ref(2.49);
    const subTotal = ref();
    const isShowAddMemberModel = ref(false);
    const isShowSuccessfullModal = ref(false);

    const loading = ref(false);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const customer = ref("") as any;

    const stripe = ref(null);
    const sessionId = ref(null);
    const redirectUrl = ref("");
    const isShowNextButton = ref(false);
    const paymentStatus = ref("");

    const createdWorkstation = ref(null) as any;
    const isWorkStationCreated = ref(false);
    const yearlyPlanLoading = ref(false);
    const monthlyPlanLoading = ref(false);
    const acceptAggreement = ref(false);
    const currentStep = ref(1);
    const currentSelectedTab = ref(null) as any;
    const tabListItems = [
      {
        id: 1,
        tab: "Company Info",
        name: "Company Info",
        header: "So, you're a registered company...",
        title: "What should we call your business?",
        description:
          "Provide us with your preferred name for your trade workstation.",
        hideAction: true,
      },
      {
        id: 2,
        tab: "Profile",
        name: "profile",
        header: "Company Logo",
        title: "Upload company logo",
        description:
          "Please upload a profile picture to help others identify your business.",
        hideAction: true,
      },
      {
        id: 3,
        tab: "Terms & Conditions",
        name: "termCondition",
        title: "Terms & Conditions",
        header: "Terms & Conditions",
        description:
          "Please read through and agree to the terms & conditions to access your iknowa platform.",
        hideAction: true,
      },
      {
        id: 4,
        tab: "Subscription",
        name: "subscription",
        header: "Choose a plan that works for youn",
        title: "Choose Your Workstation Subscription Plan",
        description:
          "Grow your business - Get quality jobs, manage your operations, and take advantage of subsidised up-skilling courses.",
        hideAction: true,
      },
    ];

    const tradingNameRef = ref(null);
    const validation = ref({
      tradingName: [(v: any) => !!v || "Workstation name is required"],
    });

    const getLimitedCompanyWorkStationModal = computed(
      () => store.getters[`${WORKSTATION}/getLimitedCompanyWorkStationModal`]
    );

    const lineItems = ref([
      { price: "price_1LrwxIHMbjzNI6kGI9hh6itL", quantity: 1 },
    ]);

    const onClickBackButton = () => {
      const currentTabIndex = tabListItems.findIndex(
        (tab) => tab.name === currentSelectedTab.value.name
      );
      if (currentTabIndex === 0) {
        ctx.emit("onClickCloseTab");
        return;
      }
      // if (currentTabIndex === 4 && planType.value) {
      //   return;
      // }
      if (currentTabIndex === 4) {
        currentStep.value = currentTabIndex;
        isShowLimitedCompanyWorkStationModal.value = false;

        currentSelectedTab.value = tabListItems[currentStep.value];
        paymentStatus.value = activeUserWorkstation.value.subscription
          ? "paid"
          : "failed";
        return;
      }
      currentSelectedTab.value = tabListItems[currentStep.value - 1];
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        activeLimitedCompanyWorkStationModal: currentStep.value,
      });
    };
    const goToNextTab = () => {
      const currentTabIndex = tabListItems.findIndex(
        (tab) => tab.name === currentSelectedTab.value.name
      );
      currentStep.value = currentTabIndex + 1;
      if (currentStep.value < tabListItems.length) {
        currentSelectedTab.value = tabListItems[currentStep.value];
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          activeLimitedCompanyWorkStationModal: currentStep.value + 1,
        });
      }
    };

    const onClickContinue = async () => {
      goToNextTab();
    };

    const onHandleClickClose = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        activeLimitedCompanyWorkStationModal: 1,
        isShowWorkStationModal: false,
        isShowLimitedCompanyWorkStation: false,
        createdWorkstationId: null,
      });
    };

    const profileImageAttachment = ref(null);
    const fileUploadErrorMsg = ref("");
    const onClickContinueUploadImage = async () => {
      try {
        if (imageData.value) {
          isWorkStationCreated.value = true;
          const formData = new FormData();
          formData.append("file", imageData.value);
          const response = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            formData
          );
          profileImageAttachment.value = response?.attachment;
        }
        goToNextTab();
      } catch (error: any) {
        console.log("");
        fileUploadErrorMsg.value = error?.response?.data?.message;
      } finally {
        isWorkStationCreated.value = false;
      }
    };

    const onClickContinueSaveLogoAndName = async () => {
      try {
        if (!tradingName.value) return;
        const companyName = tradingName.value;
        const type = BusinessDetailTypeEnum.LIMITED_COMPANY;
        // isWorkStationCreated.value = true;
        await store.dispatch(`${USER_STORE}/updateUser`, {
          userId: user.value?.id,
          userData: { hasBusinessType: true },
        });
        isWorkStationCreated.value = true;
        createdWorkstation.value = await store.dispatch(
          `${WORKSTATION}/createUserWorkstation`,
          {
            userId: user.value?.id,
            userWorkstation: {
              name: companyName,
              companyName,
              businessType: type,
              workstationAccountType: BusinessDetailTypeEnum.LIMITED_COMPANY,
            },
          }
        );
        createdWorkstation.value = await store.dispatch(
          `${WORKSTATION}/updateUserWorkstation`,
          {
            userId: user.value?.id,
            userWorkstationId: createdWorkstation.value.id,
            payload: {
              profileImage: profileImageAttachment.value || null,
            },
          }
        );
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          createdWorkstationId: createdWorkstation.value.id,
        });
        await store.dispatch(
          `${WORKSTATION}/getUserWorkstations`,
          user.value?.id
        );

        await store.dispatch(`${USER_STORE}/initializeUserState`);

        await store.dispatch(`${USER_STORE}/getAccountStatuses`);

        // goToNextTab();
      } catch (error) {
        console.log();
      } finally {
        // isWorkStationCreated.value = false;
      }
    };

    const onChooseMonthlyOption = () => {
      goToNextTab();
    };

    const initializeStripe = () => {
      let stripe = null;

      if ("Stripe" in window) {
        const { Stripe } = window as any;

        // eslint-disable-next-line no-new
        stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
      }

      return stripe;
    };

    const initializeStripePayment = async (
      isTrial: boolean,
      oneTimePayment: boolean
    ) => {
      stripe.value = initializeStripe();

      if (stripe.value) {
        await onCheckout(isTrial, oneTimePayment);
      }
    };

    const onCheckout = async (isTrial: boolean, oneTimePayment: boolean) => {
      await createSession(isTrial, oneTimePayment);
      if (sessionId.value) {
        (stripe.value as any)
          .redirectToCheckout({
            sessionId: sessionId.value,
          })
          .then((result: any) => {
            if (result.error) {
              console.log();
            }
          });
      }
    };
    onBeforeMount(() => {
      getCheckoutSession();

      currentSelectedTab.value =
        tabListItems[
          typeof getLimitedCompanyWorkStationModal.value === "number"
            ? getLimitedCompanyWorkStationModal.value - 1
            : 0
        ];

      if (
        currentSelectedTab.value.name === "logo" &&
        !tradingName.value.length
      ) {
        onClickBackButton();
      }
    });
    const getCheckoutSession = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params?.session_id) {
          const ws_modalData: any = window.localStorage.getItem("ws_modalData");
          if (JSON.parse(ws_modalData)?.createdWorkstationId) {
            userService.deleteWorkStationData(
              user.value?.id,
              JSON.parse(ws_modalData)?.createdWorkstationId
            );
            store.dispatch(`${WORKSTATION}/setWsModal`, {
              activeLimitedCompanyWorkStationModal: 1,
              isShowLimitedCompanyWorkStation: false,
              isShowWorkStationModal: true,
              createdWorkstationId: null,
            });
          }
          return;
        }
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          createdWorkstationId: null,
        });
        isShowLimitedCompanyWorkStationModal.value = false;
        const url = `/payment/${user.value?.id}/session?sessionId=${params.session_id}&isWorkstation=1`;

        const response: any = await $axios.get(url);
        if (response?.status === 400) {
          paymentStatus.value = "failed";
          return;
        }
        if (response.payment_status !== "paid") {
          await userService.deleteWorkStationData(
            user.value?.id,
            response.metadata.userWorkstationId
          );
          return;
        }
        console.log(response, "responseee");
        paymentStatus.value = response?.payment_status;
        planType.value = parseInt(response.metadata?.planType, 10);
        await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
          userId: user.value?.id,
          userWorkstationId: response.metadata?.userWorkstationId,
        });
        store.commit(`${USER_STORE}/setCertificates`, []);
        store.commit(`${USER_STORE}/setInsurances`, []);
        store.commit(`${USER_STORE}/setUserSpecialisms`, []);
        await store.dispatch(
          `${WORKSTATION}/getUserWorkstations`,
          user.value.id
        );
        await store.dispatch(`${USER_STORE}/initializeUserState`);
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };

    const createSession = async (isTrial = false, oneTimePayment = false) => {
      const user = cloneObject(store.getters[`${USER_STORE}/user`]);
      const { id: userId, userStripeCustomer, email } = user;

      const productName = "tradesperson-profile";

      const entity = JSON.stringify({
        id: userId,
        name: "tradesperson-profile",
      });

      if (!userStripeCustomer?.length) {
        await $axios
          .post(`/payment/${userId}/customer`, {
            email,
            entity,
          })
          .then(async (customerResponse) => {
            await store.dispatch(`${USER_STORE}/saveUserStripePayment`, [
              customerResponse,
            ]);
            customer.value = customerResponse;
          })
          .catch((e) => {
            console.log();
          });
      } else {
        const [stripeCustomer] = userStripeCustomer;
        customer.value = stripeCustomer;
      }

      const host = `${window.location.protocol}//${window.location.host}`;
      redirectUrl.value = `${host}/dashboard`;

      const { customerId } = customer.value;

      if (customerId) {
        await $axios
          .post(`/payment/${userId}/session`, {
            customerId,
            price: parseFloat(finalTotalPrice.value),
            redirectUrl: redirectUrl.value,
            productName,
            metadata: JSON.stringify({
              name: createdWorkstation.value?.name,
              companyName: createdWorkstation.value?.name,
              userWorkstationId: createdWorkstation.value?.id,
              userId,
              totalMember: totalMember.value,
              planType: planType.value,
              businessType: BusinessDetailTypeEnum.LIMITED_COMPANY,
              workstationAccountType: BusinessDetailTypeEnum.LIMITED_COMPANY,
              capacity: "100MB",
            }),
            isTrial,
            oneTimePayment,
          })
          .then(async (sessionResponse: any) => {
            if (sessionResponse) {
              sessionId.value = sessionResponse.id;
            }
            console.log(sessionResponse, "sessionResponse");
            console.log(planType, "planType");
          })
          .catch((error) => {
            console.log();
          });
      }
    };
    const onClickPaymentStatusContinue = () => {
      const url = new URL(window.location.href);

      // Remove all query parameters
      url.search = "";

      // Replace the current URL without query parameters
      history.replaceState(null, "", url.toString());
      isShowLimitedCompanyWorkStationModal.value = true;

      // goToNextTab();
      isShowLimitedCompanyWorkStationModal.value = false;
      isShowSuccessfullModal.value = true;
    };

    const isProfileVerifiable = computed(
      () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
    );

    const onFileChange = async (event: any) => {
      fileUploadErrorMsg.value = "";
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];
        if (
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg"
        ) {
          imageUrl.value = URL.createObjectURL(file);
          imageData.value = file;
        }
      } else {
        console.error("Error: No file selected.");
      }
    };
    const clearImageUrl = () => {
      imageUrl.value = "";
    };

    const onClickPaymentPlan = async (value: number) => {
      try {
        // isShowAddMemberModel.value = true;
        planType.value = value;
        if (value === PaymentPlanType.YEARLY) {
          planTypeName.value = "yearly";
          priceValue.value = "£7.99";
          subTotal.value = "95.90";
          yearlyPlanLoading.value = true;
        } else if (value === PaymentPlanType.MONTHLY) {
          planTypeName.value = "monthly";
          priceValue.value = "£9.99";
          subTotal.value = "9.99";
          monthlyPlanLoading.value = true;
        }
        await onClickContinueSaveLogoAndName();
        initializeStripePayment(false, false);
      } catch (error) {
        console.log(error);
      }
    };
    const handleTotalMember = (type: string) => {
      if (type === "inc") {
        totalMember.value += 1;
        subTotal.value = (
          parseFloat(subTotal.value) + membershipPrice.value
        ).toFixed(2);
      } else {
        if (totalMember.value < 1) return;
        subTotal.value = (
          parseFloat(subTotal.value) - membershipPrice.value
        ).toFixed(2);
        totalMember.value -= 1;
      }
    };
    const getVatTaxValue = computed(() =>
      ((parseFloat(subTotal.value) * 20) / 100).toFixed(2)
    );
    const finalTotalPrice = computed(
      () => parseFloat(subTotal.value).toFixed(2)
      // (
      //   parseFloat(subTotal.value) +
      //   (parseFloat(subTotal.value) * 20) / 100
      // ).toFixed(2)
    );
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );
    const onClickIdVerification = async () => {
      try {
        const response: any = await store.dispatch(
          `${WORKSTATION}/sendUserKYBProcessMail`,
          {
            email: user.value.email,
          }
        );

        response["custom_user_workstation_id"] =
          activeUserWorkstation.value?.id;
        response["custom_user_id"] = user.value.id;
        await store.dispatch(
          `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
          response
        );
        await $axios.post(`/silt/verification/kyb`, response);
        isShowKYBInfoModal.value = true;
        isShowLimitedCompanyWorkStationModal.value = false;
      } catch (error) {
        console.log();
      }
    };
    const closeKYBModal = () => {
      isShowKYBInfoModal.value = false;
      isShowLimitedCompanyWorkStationModal.value = true;
      goToNextTab();
    };

    const onClickTermAndConditionNext = () => {
      isShowLimitedCompanyWorkStationModal.value = false;
      isShowSuccessfullModal.value = true;
      isShowKYBInfoModal.value = false;
    };

    const onClickLimitedCompanyDashboard = async () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        activeLimitedCompanyWorkStationModal: 1,
        isShowWorkStationModal: false,
        isShowLimitedCompanyWorkStation: false,
      });
      await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
    };

    //address model
    const selectedUPRN = ref("");
    const selectedDPA = ref(null) as any;
    const isManualForm = ref(false);
    const isShowAddressData = ref(false);
    const isChangeManualAddress = ref(false);
    const addressFormRef = ref(null) as any;
    const addressForm = ref({
      postCode: "",
      address: "",
      place: "",
      latitude: "",
      longitude: "",
    }) as any;
    const billingAddressForm = ref(null);
    const addressListFromPostcode = ref([]) as any;
    const errorMessagePostcode = ref("");
    const isActiveNextButton = ref(false);
    const loadingContinue = ref(false);
    const isDisabledNextButton = computed(() =>
      /^[a-zA-Z0-9\s]+$/.test(addressForm.value.postCode)
    );
    const addressRule = ref({
      address: [(v: string) => !!v || "Address is required"],
      place: [(v: string) => !!v || "City/Town is required"],
      postCode: [(v: string) => !!v || "PostCode is required"],
    });
    const onSelectedAddress = (DPA: any) => {
      selectedUPRN.value = DPA?.UPRN;
      selectedDPA.value = DPA;
      addressForm.value.address = removePostCodeAndPostTownInFullAddress(
        DPA.POSTCODE,
        DPA.POST_TOWN,
        DPA.ADDRESS
      );
      addressForm.value.place = DPA.POST_TOWN;
      addressForm.value.postCode = DPA.POSTCODE;
      addressForm.value.latitude = DPA.LAT;
      addressForm.value.longitude = DPA.LNG;
      billingAddressForm.value = DPA;
      if (billingAddressForm.value) {
        isShowAddressData.value = true;
      }
    };

    const removePostCodeAndPostTownInFullAddress = (
      postcode: string,
      postTown: string,
      address: string
    ) => {
      if (!postcode && !address && !postTown) {
        console.log("Please enter a word to remove and the address.");
        return;
      }

      const concatRemovedWord = `, ${postTown}, ${postcode}`;

      // Escape special characters in the word to be removed
      const escapedWord = concatRemovedWord.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );

      // Create a regular expression to match the word to be removed
      const regex = new RegExp(`\\b${escapedWord}\\b`, "gi");

      // Remove the word from the input string
      return address.replace(regex, "").trim();
    };
    const onClickFindAddress = async () => {
      const isFormValid = await addressFormRef.value.validate();
      if (!isFormValid.valid) return;
      if (billingAddressForm.value) {
        isShowAddressData.value = true;
      } else {
        try {
          isActiveNextButton.value = true;
          const response = await userService.fetchAddressFromPostCode(
            addressForm.value.postCode
          );
          addressListFromPostcode.value = response.data.results;
          if (response.status === 200) {
            errorMessagePostcode.value = "";
            addressForm.value.address =
              response.data?.result?.primary_care_trust;
            addressForm.value.place = response.data?.result?.region;
          } else if (response.status === 400) {
            errorMessagePostcode.value = "Invalid postcode";
            console.error("Failed to fetch address data");
          }
        } catch (error) {
          errorMessagePostcode.value = "Invalid postcode";
          console.error("Error fetching address data:", error);
        } finally {
          isActiveNextButton.value = false;
        }
      }
    };
    const onClickAddressContinue = async () => {
      const isFormValid = await addressFormRef.value.validate();
      if (!isFormValid.valid) return;
      loadingContinue.value = true;
      try {
        if (isManualForm.value) {
          await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
            userId: user.value.id,
            formData: {
              businessAddress: `${addressForm.value.address} ${addressForm.value.place}`,
              businessPostcode: addressForm.value.postCode,
              type: user.value?.userBusinessDetails?.type,
              userVerificationStatus:
                user.value?.userBusinessDetails?.userVerificationStatus,
            },
          });
        } else {
          if (!selectedDPA.value) return;
          await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
            userId: user.value.id,
            formData: {
              businessAddress: selectedDPA.value?.ADDRESS,
              businessPostcode: addressForm.value.postCode,
              latitude: addressForm.value.latitude.toString(),
              longitude: addressForm.value.longitude.toString(),
              type: user.value?.userBusinessDetails?.type,
              userVerificationStatus:
                user.value?.userBusinessDetails?.userVerificationStatus,
            },
          });
        }

        store.dispatch(`${USER_STORE}/initializeUserState`);
        goToNextTab();
      } catch (error) {
        console.log("error:", error);
      } finally {
        loadingContinue.value = false;
        selectedDPA.value = null;
        selectedUPRN.value = "";
      }
    };
    const showManualForm = () => {
      isChangeManualAddress.value = true;
      isShowAddressData.value = true;
      isManualForm.value = true;
      resetForm();
    };

    watch(
      () => addressForm.value.postCode,
      (newValue) => {
        if (newValue === null || newValue === "") {
          addressListFromPostcode.value = [];
        }
      },
      { deep: true, immediate: true }
    );

    const changeAddress = () => {
      isShowAddressData.value = false;
      isManualForm.value = false;
      selectedDPA.value = null;
      selectedUPRN.value = "";
      resetForm();
    };

    const resetForm = () => {
      addressForm.value.address = "";
      addressForm.value.place = "";
      addressForm.value.postCode = "";
      addressForm.value.latitude = "";
      addressForm.value.longitude = "";
      billingAddressForm.value = null;
    };

    const onClickFreePlan = async () => {
      await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
        userId: user.value?.id,
        userWorkstationId: createdWorkstation.value?.id,
      });
      await store.dispatch(`${WORKSTATION}/getUserWorkstations`, user.value.id);
      await store.dispatch(`${USER_STORE}/initializeUserState`);
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      isShowLimitedCompanyWorkStationModal.value = false;
      isShowSuccessfullModal.value = true;
    };

    return {
      isShowLimitedCompanyWorkStationModal,
      onClickContinue,
      tradingName,
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      lineItems,
      loading,
      checkoutRef,
      initializeStripePayment,
      tabListItems,
      currentSelectedTab,
      onClickBackButton,
      goToNextTab,
      isShowNextButton,
      onChooseMonthlyOption,
      onClickPaymentStatusContinue,
      paymentStatus,
      isProfileVerifiable,
      onFileChange,
      clearImageUrl,
      imageUrl,
      onClickPaymentPlan,
      PaymentPlanType,
      handleTotalMember,
      isShowAddMemberModel,
      priceValue,
      subTotal,
      totalMember,
      planType,
      planTypeName,
      onClickIdVerification,
      isShowKYBInfoModal,
      closeKYBModal,
      onClickTermAndConditionNext,
      membershipPrice,
      getVatTaxValue,
      finalTotalPrice,
      addressRule,
      isShowAddressData,
      addressForm,
      addressListFromPostcode,
      onSelectedAddress,
      errorMessagePostcode,
      isDisabledNextButton,
      isActiveNextButton,
      onClickFindAddress,
      addressFormRef,
      loadingContinue,
      onClickAddressContinue,
      changeAddress,
      isChangeManualAddress,
      isShowSuccessfullModal,
      activeUserWorkstation,
      isWorkStationCreated,
      onClickLimitedCompanyDashboard,
      onClickFreePlan,
      onClickContinueSaveLogoAndName,
      validation,
      tradingNameRef,
      yearlyPlanLoading,
      showManualForm,
      selectedUPRN,
      acceptAggreement,
      monthlyPlanLoading,
      getLimitedCompanyWorkStationModal,
      onHandleClickClose,
      selectedDPA,
      fileUploadErrorMsg,
      onClickContinueUploadImage,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/modules/workstation/limitedcompany/styles/limitedcompany.scss";

:deep(.v-stepper-item) {
  width: 100%;
  max-width: 25px;
}

:deep(.v-stepper-item__content) {
  display: none;
}

:deep(.v-stepper-item--selected) {
  max-width: unset;
  .v-stepper-item__content {
    display: inline-block;
  }
}

@media screen and (min-width: 568px) {
  :deep(.v-stepper-item) {
    max-width: unset;
  }
  :deep(.v-stepper-item__content) {
    display: inline-block;
  }
}

.address {
  flex-basis: auto;
  margin-top: 26px;
  h2 {
    color: #0c0f4a;
    margin-bottom: 32px;
  }
  p {
    cursor: pointer;
  }

  .el-button {
    margin-top: 2rem;
  }

  &__input-manually {
    .el-input {
      margin-top: 1rem;
    }
  }

  &__postcodes {
    max-height: 245px;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    overflow-y: auto;
    list-style: none;
    background-color: rgba($blueDark, 0.05);
    border-radius: 16px;
    padding: 12px;
    margin: 12px 0;

    li {
      color: rgba($blueDark, 1);
      @include fluidFont(14, 14, 1);
      text-align: left;
      padding: 4px 12px;
      border-radius: 30px !important;
      font-weight: 400;
      line-height: 25px;
      cursor: pointer;

      &:hover {
        background-color: rgba($orange, 1);
        color: rgba($white, 1);
      }
    }

    li.is-active {
      background-color: rgba(255, 165, 0, 0.6);
      color: #fff;
      border-radius: 20px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.modal_wrapper__content {
  height: calc(100dvh - 500px);
  margin-top: 24px;
  overflow: auto;
}

.onboarding-not-service {
  margin-top: 30px;
  @include fluidFont(14, 14, 1);
  font-weight: 500;
  color: rgba($blueDark, 0.5);
  letter-spacing: 1px;

  span {
    font-weight: 700;
    color: #ffa500;
    letter-spacing: 0.25px;
    cursor: pointer;
  }
}
</style>
