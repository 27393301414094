<template>
  <CommonDialog
    :isShowHeader="false"
    :isShowBackButton="false"
    width="832px"
    max-width="100%"
    persistent
    :className="['property__upload__modal']"
  >
    <template v-slot:body>
      <v-icon icon="mdi-close" class="close__icon" @click="onClose"></v-icon>
      <div class="modal__content">
        <div class="left_upload_img">
          <h4>Upload images and files.</h4>

          <div class="upload__area">
            <div class="uploade-wrapper">
              <v-file-input
                class="upload_file"
                label="Upload"
                :loading="loading"
                append-inner-icon="mdi-image-plus"
                variant="solo-filled"
                v-model="selectedFile"
                :class="{ 'added-img': imageUrl }"
                @change="onFileChange"
                @click:clear="clearImageUrl"
                multiple
              >
              </v-file-input>
              <v-img
                v-if="imageUrl"
                class="uploaded-img"
                :width="200"
                aspect-ratio="16/9"
                cover
                :src="imageUrl"
              ></v-img>

              <v-icon
                class="uploaded-img"
                icon="mdi mdi-image-outline"
              ></v-icon>

              <v-icon icon="mdi mdi-file-image-box"></v-icon>
              <p class="error_msg" v-if="errorMessage">
                {{ errorMessage }}
              </p>
            </div>
            <div class="upload__text">
              <p>
                <strong>Drag and drop documents, or</strong>
                <span>Browse</span>
              </p>
              <p class="">
                Attachments such as images, design ideas and documents help
                better understand the project and provide increasingly accurate
                estimates.
              </p>
            </div>
          </div>
          <div class="selected-info"></div>
        </div>
        <div class="image__upload__wrapper">
          <div v-if="attachmentsData.length" class="tw-w-full">
            <h5>Uploaded Files</h5>
            <div class="uploaded_details_wrapper" v-if="!loading">
              <div
                class="show_uploaded_details"
                v-for="(attachment, index) in attachmentsData"
                :key="index"
              >
                <v-img
                  v-if="attachment?.uploadedImages?.type == 'image'"
                  class="uploaded-img"
                  :width="156"
                  :height="88"
                  aspect-ratio="16/9"
                  cover
                  :src="attachment?.uploadedImages?.url"
                ></v-img>
                <v-icon
                  v-else
                  class="uploaded-img"
                  icon="mdi mdi-image-outline"
                ></v-icon>
                <div class="prop_img_details tw-mt-3">
                  <h5>{{ attachment?.uploadedImages?.name }}</h5>
                  <v-btn
                    :ripple="false"
                    @click="
                      removeImageFromAttachments(
                        index,
                        attachment?.uploadedImages?.id
                      )
                    "
                    variant="text"
                    class="button tw-min-h-4"
                  >
                    <img :src="require('@/assets/icons/delete.svg')" alt="" />
                  </v-btn>
                </div>
              </div>
            </div>
            <CommonLoader class="common__loader__center" :loading="loading" />
          </div>
          <div v-else class="blank_project">
            <p>Your added project attachments will show up here</p>
          </div>
        </div>
      </div>

      <div class="footer-buttons">
        <v-btn
          class="button button-purple-border"
          @click="onClose"
          :disabled="loading"
          variant="outlined"
        >
          cancel
        </v-btn>
        <v-btn
          class="button button-orange"
          :loading="loading"
          @click="onClickSaveButtonAttachmentsModal"
        >
          save
        </v-btn>
      </div>
    </template>
  </CommonDialog>
</template>
<script setup>
import CommonDialog from "@/core/components/CommonDialog.vue";
import CommonLoader from "@/core/components/CommonLoader.vue";
import projectEstimationService from "@/core/services/project-estimation.service";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { computed } from "vue";
import { onMounted } from "vue";
import { ref } from "vue";
import { useStore } from "vuex";
const emits = defineEmits(["on-close"]);
const props = defineProps({
  estimatePhaseId: {
    type: Number,
  },
  projectEstimateId: {
    type: Number,
  },
  phaseIndex: {
    type: Number,
  },
});
const store = useStore();
const removeAttachmentsIds = ref([]);
const attachmentsData = ref([]);
const loading = ref(false);
const selectedFile = ref([]);
const imageUrl = ref(null);
const errorMessage = ref("");
const uploadedImages = ref(null);
const onFileChange = (event) => {
  const fileInput = event.target;
  errorMessage.value = "";

  if (fileInput.files && fileInput.files.length > 0) {
    Array.from(fileInput.files).forEach((file, index) => {
      if (file.type.includes("image")) {
        // Check image size limit (200MB)
        if (file.size <= 200 * 1024 * 1024) {
          imageUrl.value = URL.createObjectURL(file);
          uploadedImages.value = {
            type: "image",
            url: imageUrl.value,
            name: file.name,
            imageData: file,
            id: file.name + "-" + Date.now() + index,
            estimatePhaseId: props.estimatePhaseId
              ? props.estimatePhaseId
              : null,
            projectEstimateId: props.projectEstimateId
              ? props.projectEstimateId
              : null,
          };
          addPropertyImage();
        } else {
          errorMessage.value = "Image size exceeds the limit (200MB).";
          // Handle error message or display notification
        }
      } else if (file.type.includes("video")) {
        // Check video size limit (20MB)
        if (file.size <= 10 * 1024 * 1024) {
          uploadedImages.value = {
            type: "video",
            name: file.name,
            imageData: file,
          };
          addPropertyImage();
          // Process video file
        } else {
          errorMessage.value = "Video size exceeds the limit (20MB).";
          // Handle error message or display notification
        }
      } else {
        // Allow other file types without size validation
        imageUrl.value = URL.createObjectURL(file);
        uploadedImages.value = {
          url: imageUrl.value,
          name: file.name,
          imageData: file,
        };
        addPropertyImage();
      }
    });
  } else {
    console.error("Error: No file selected.");
  }
};

const clearImageUrl = () => {
  imageUrl.value = "";
};
const onClose = () => {
  emits("on-close");
};
const addPropertyImage = () => {
  attachmentsData.value.push({
    uploadedImages: { ...uploadedImages.value },
  });
  uploadedImages.value = null;
  selectedFile.value = [];
  imageUrl.value = "";
};
const removeImageFromAttachments = (index, attachmentId) => {
  attachmentsData.value.splice(index, 1);
  if (attachmentId) removeAttachmentsIds.value.push(attachmentId);
};

const onClickSaveButtonAttachmentsModal = async () => {
  if (!props.projectEstimateId && !props.estimatePhaseId) {
    store.dispatch(`${JOB_TEMPLATE_STORE}/setPhaseAttachments`, {
      phaseIndex: props.phaseIndex,
      attachments: attachmentsData.value,
    });
  } else {
    try {
      loading.value = true;
      const formData = new FormData();
      attachmentsData.value.forEach((data) => {
        if (!data.uploadedImages.id)
          formData.append("files", data.uploadedImages.imageData);
      });
      if (formData)
        await projectEstimationService.addProjectPhaseAttachment(
          props.projectEstimateId,
          props.estimatePhaseId,
          formData
        );

      if (removeAttachmentsIds.value.length) {
        await Promise.all(
          removeAttachmentsIds.value.map(async (id) => {
            await projectEstimationService.deleteEstimationAttachment(id);
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      removeAttachmentsIds.value = [];
      loading.value = false;
      getAttachments();
    }
  }
  onClose();
};
const getAttachments = async () => {
  try {
    if (props.projectEstimateId && props.estimatePhaseId) {
      loading.value = true;
      const response =
        await projectEstimationService.getEstimatePhaseAttachments(
          props.projectEstimateId,
          props.estimatePhaseId
        );

      attachmentsData.value = response?.estimatePhaseAttachment.map(
        (attachment) => {
          return {
            uploadedImages: {
              imageData: attachment?.attachment,
              name: attachment?.originalName,
              url: attachment?.attachment,
              id: attachment?.id,
              estimatePhaseId: props.estimatePhaseId,
              projectEstimateId: props.projectEstimateId,
            },
          };
        }
      );
    }
  } catch (error) {
    console.log();
  } finally {
    loading.value = false;
  }
};
const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
onMounted(async () => {
  if (props.estimatePhaseId && props.projectEstimateId) {
    await getAttachments();
  } else {
    const phaseAttachments =
      estimateForm.value.projectStages[props.phaseIndex]?.attachments;
    if (phaseAttachments.length) {
      attachmentsData.value = JSON.parse(JSON.stringify(phaseAttachments));
    }
  }
});
</script>
<style lang="scss">
.common__loader__center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.v-field__clearable {
  position: absolute;
  z-index: 1;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  opacity: 1;
  overflow: unset;
  grid-area: unset;
  align-items: center;
  justify-content: center;
  background-color: rgba($black, 0.5);
  color: rgba($white, 1);
}
</style>
