<template>
  <section
    class="tw-flex tw-flex-col tw-w-full tw-gap-4 add_historical tw-h-[calc(100dvh-220px)] tw-overflow-auto no-scrollbar lg:tw-h-full"
  >
    <div
      v-if="screenType !== PREVIEW_SCREEN"
      class="tw-flex-row-between tw-border-b-solid-custom tw-pb-7 tw-pl-0 tw-box-border"
    >
      <v-btn
        class="back_button"
        @click="onBack"
        variant="tonal"
        prepend-icon="mdi-chevron-left"
        >Back</v-btn
      >
      <Button
        label="next"
        class="!tw-max-w-[316px] md:!tw-hidden"
        @click="onNext"
        :isActive="isFormValid"
      />
    </div>
    <div
      class="tw-flex tw-flex-col tw-gap-6 tw-w-[70%] md:tw-w-full"
      v-if="screenType === DEFAULT"
    >
      <div
        class="text__editor tw-flex tw-flex-col tw-gap-6 tw-pb-6 tw-border-b-dashed-custom"
      >
        <v-text-field
          class="projecttitle_select"
          placeholder="Project title"
          v-model="historicalProjectForm.title"
          variant="underlined"
          density="compact"
          :rules="validation.projectTitle"
        ></v-text-field>
        <div class="custom_editor tw-h-[234px] tw-mb-6">
          <quill-editor
            v-model:value="historicalProjectForm.description"
            :options="state.editorOption"
            :disabled="state.disabled"
            @change="onEditorChange($event)"
            class="editor_ql"
          />
        </div>
      </div>
      <div
        class="tw-flex tw-flex-col tw-gap-2 tw-pb-6 tw-border-b-dashed-custom tw-items-start tw-justify-start"
      >
        <Text variant="h4" textWeight="400">Project Type</Text>
        <Text
          variant="p"
          textWeight="500"
          textColor="rgba(12, 15, 74, 0.5)"
          class="!tw-text-left"
          >Please choose the property type :</Text
        >
        <div
          class="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-start tw-justify-start tw-mt-4"
        >
          <v-select
            v-model="historicalProjectForm.projectType"
            :items="filterProjectType"
            item-title="label"
            item-value="value"
            class="custom_input_field"
            placeholder="Select Project Type"
            density="compact"
            variant="outlined"
            color="#fff"
            :menu-props="{ contentClass: 'project_type__selector' }"
          ></v-select>
        </div>
      </div>
      <!-- class="tw-flex tw-flex-col tw-gap-4 tw-pb-6 tw-border-b-dashed-custom tw-items-start tw-justify-start" -->
      <div class="tw-text-left">
        <Text variant="h6" textWeight="600">Select Specialisms</Text>
        <!-- class="tw-w-full tw-flex tw-flex-col tw-gap-3 tw-items-start tw-justify-start tw-bg-white tw-rounded-lg tw-py-6 tw-px-4 tw-box-border" -->
        <div class="tw-mt-4">
          <Button
            :label="
              projectSpecialisms.length
                ? 'Add Specialisms'
                : 'Select Specialisms'
            "
            variant="tertiary"
            background="transparent"
            class="add__items !tw-w-auto !tw-capitalize !tw-text-xs tw-tracking-normal !tw-p-0 !tw-h-auto !tw-min-h-auto !tw-font-normal !tw-text-[#0C0F4A]"
            @click="onClickToggleSpecialismForm"
            ><template #prefix
              ><v-icon
                icon="mdi-plus-circle"
                color="#FAA500"
              ></v-icon></template
          ></Button>
          <div class="specialism__wrapper" v-if="projectSpecialisms.length">
            <div class="specialism__wrapper__content">
              <div class="specialism-card-list">
                <div
                  class="specialism-card-list__item"
                  v-for="specialism in projectSpecialisms"
                  :key="specialism.id"
                >
                  <div class="specialism-card-list__item-author">
                    <div
                      class="specialism-card-list__item-author-header"
                      :class="specialismCategoryClass(specialism?.generatedBy)"
                    >
                      <div class="speicalism__icon">
                        <img
                          v-if="
                            specialismCategoryClass(specialism?.generatedBy) ===
                            'krystal'
                          "
                          :src="require('@/assets/icons/krystal.svg')"
                          alt="krystalImg"
                        />
                        <img
                          v-if="
                            specialismCategoryClass(specialism?.generatedBy) ===
                            'property'
                          "
                          :src="require('@/assets/icons/house.svg')"
                          alt="houseimage"
                        />
                      </div>
                      <h6>{{ specialism?.generatedBy }}</h6>

                      <v-icon icon="mdi-information-variant-circle-outline" />
                    </div>
                    <div class="specialism-card-list__item-author-content">
                      <div class="category_icon">
                        <img
                          :src="
                            require('@/assets/category-icons/' +
                              specialism?.category?.iconName +
                              '.svg')
                          "
                          alt="Image"
                        />
                      </div>
                      <div class="category_text">
                        <h5>{{ specialism?.label }}</h5>
                        <p>{{ specialism?.category?.poLabel }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-justify-start">
        <Text variant="h4" textWeight="400">Upload Media</Text>
        <div
          class="tw-w-full tw-flex tw-gap-3 tw-items-center tw-bg-white tw-rounded-lg tw-py-5 tw-px-4 tw-box-border md:tw-flex-col md:tw-items-start"
          :class="{ 'tw-flex-col !tw-items-start': projectAttachments.length }"
        >
          <Text
            v-if="!projectAttachments.length"
            variant="span"
            textWeight="600"
            textColor="rgba(12, 15, 74, 0.5)"
            >No documents added</Text
          >
          <Button
            label="Add Documents"
            variant="tertiary"
            background="transparent"
            class="!tw-w-auto !tw-capitalize !tw-text-xs tw-tracking-normal !tw-p-0 !tw-h-auto !tw-min-h-auto !tw-font-normal !tw-text-[#0C0F4A]"
            @click="onClickToggleUploadAttachmentUpload"
            ><template #prefix
              ><v-icon
                icon="mdi-plus-circle"
                color="#FAA500"
              ></v-icon></template
          ></Button>
          <div
            v-for="(attachment, index) in projectAttachments"
            :key="index"
            class="tw-w-full tw-flex tw-flex-col tw-gap-1 tw-items-start tw-border-b-solid-custom tw-pb-4 tw-box-border"
          >
            <v-img
              class="tw-rounded-lg"
              :class="{
                'tw-hidden':
                  attachment?.uploadedImages?.imageData.type ===
                    'application/pdf' ||
                  attachment?.uploadedImages?.imageData.type ===
                    'application/msword',
              }"
              :width="156"
              :height="88"
              aspect-ratio="16/9"
              cover
              :src="attachment?.uploadedImages?.url"
            ></v-img>
            <div class="tw-flex-row-between">
              <Text variant="span">{{ attachment?.uploadedImages?.name }}</Text>
              <img
                class="tw-cursor-pointer"
                @click="removeImageFromAttachments(index)"
                src="@/assets/icons/delete.svg"
              />
            </div>
          </div>
        </div>
      </div>

      <Button
        label="next"
        class="!tw-max-w-full !tw-hidden lg:!tw-hidden md:!tw-block"
        @click="onNext"
        :isActive="isFormValid"
      />
    </div>

    <PropertyEstimateScreen
      v-if="screenType === ESTIMATE_SCREEN"
      :historicalProjectForm="historicalProjectForm"
      :propertyDetails="propertyDetails?.localData"
      @onNext="onNext"
    />
    <PropertyTradeNetworkScreen
      v-if="screenType === TRADE_NETWORK_SCREEN"
      :inviteContractors="historicalProjectForm.invitedContractors"
      :selectedClient="selectedClient"
      :propertyDetails="propertyDetails?.localData"
      @on-select-contractor="onSelectContractors"
      :workflowType="workflowType"
      @onNext="onNext"
    />
    <PropertyPreviewScreen
      v-if="screenType === PREVIEW_SCREEN"
      :propertyDetails="propertyDetails?.localData"
      :historicalProjectForm="historicalProjectForm"
      @on-back="onBack"
      @on-save-record="onClickSaveRecord"
      @on-edit-details="onClickEditDetailsSave"
      @on-select-contractor="onSelectContractors"
      :workflowType="workflowType"
    />
  </section>

  <!-- MODALS -->
  <PropertySpecialismModal
    v-if="isShowSpecialismModal"
    @onClickCloseTab="onClickToggleSpecialismForm"
  />

  <ConfirmSaveHistoricalProjectModal
    v-if="isConfirmSaveModal"
    @on-close="onClickToggleConfirmSave"
    @on-confirm="onSaveProject"
  />

  <HistoricalProjectSavingStepsModal
    v-if="isPostLoadingModal"
    @on-success="onSaveSuccess"
  />

  <UploadProjectAttachmentsModal
    v-if="isShowProjectAttachmentUploadModal"
    :projectAttachments="projectAttachments"
    :isPropertyOwner="true"
    @on-close="onClickToggleUploadAttachmentUpload"
  />

  <ConfirmInviteContractorsModal
    v-if="isShowConfirmContractorInviteModal"
    :projectType="historicalProjectForm.projectType"
    :historicalProjectForm="historicalProjectForm"
    @on-close="onClickToggleConfirmContractorInviteModal"
    @on-confirm="onConfirmInvite"
    @on-cancel="onCancelProjectType"
    @onConfirmProjectType="onConfirmProjectType"
  />

  <v-stepper class="onboard-stepper" v-model="currentSelectedTab.id">
    <v-stepper-header class="onboard-stepper__header">
      <v-stepper-item
        class="onboard-stepper__item"
        v-for="step in modelDetails"
        :key="step.id"
        :title="step.tab"
        :value="step.id"
      ></v-stepper-item>
    </v-stepper-header>
  </v-stepper>
</template>
<script lang="ts" setup>
import { computed, onBeforeMount, reactive, ref } from "vue";
import { useStore } from "vuex";

import Text from "@/core/components/ui/general/Text.vue";
import BackButton from "@/core/components/common/BackButton.vue";
import Button from "@/core/components/ui/general/Button.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import PropertySpecialismModal from "@/modules/Specialism/components/PropertySpecialismModal.vue";
import ConfirmSaveHistoricalProjectModal from "@/modules/properties/components/Modals/ConfirmSaveHistoricalProjectModal.vue";
import SpecialismItem from "@/core/components/common/SpecialismItem.vue";
import HistoricalProjectSavingStepsModal from "@/modules/properties/components/Modals/HistoricalProjectSavingStepsModal.vue";
import PropertyEstimateScreen from "./PropertyEstimateScreen.vue";
import PropertyTradeNetworkScreen from "./PropertyTradeNetworkScreen.vue";
import PropertyPreviewScreen from "@/modules/properties/components/PropertyPreviewScreen.vue";
import UploadProjectAttachmentsModal from "./Modals/UploadProjectAttachmentsModal.vue";
import ContractorInvitePreviewModal from "@/modules/properties/components/Modals/ContractorInvitePreviewModal.vue";
import ConfirmInviteContractorsModal from "@/modules/properties/components/Modals/ConfirmInviteContractorsModal.vue";
import { PROJECT_STORE } from "@/store/modules/project";

import { quillEditor } from "vue3-quill";
import SpecialismMultiSelectModel from "@/modules/project/modal/SpecialismMultiSelectModel.vue";
import { ElMessage } from "element-plus";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { PROPERTY_STORE } from "@/store/modules/property";
import historicalProjectService from "@/core/services/historical-project.service";
import { VAlert } from "vuetify/lib/components/index.mjs";
import {
  HistoricalProjectTypeEnum,
  HistoricalWorkFlowTypeEnum,
} from "@/core/enums/ProjectsEnum";
import moment from "moment";

const DEFAULT = "default";
const ESTIMATE_SCREEN = "estimate-screen";
const TRADE_NETWORK_SCREEN = "trade-network-screen";
const PREVIEW_SCREEN = "preview-screen";

const store = useStore();
const props = defineProps({
  workflowType: { type: String, default: HistoricalWorkFlowTypeEnum.PROPERTY },
  selectedClient: {
    type: Object,
    default: null,
  },
});
const emit = defineEmits(["on-back", "onHistoricProjectSaved"]);

const screenType = ref(DEFAULT);

const isPostLoadingModal = ref(false);

const isConfirmSaveModal = ref(false);
const isShowSpecialismModal = ref(false);
const isShowProjectAttachmentUploadModal = ref(false);
const isShowContractorInviteModal = ref(false);
const isShowConfirmContractorInviteModal = ref(false);

const state = reactive({
  content: "",
  _content: "",
  editorOption: {
    placeholder: "Description here",
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ align: [] }],
        ["clean"],
      ],
    },
    wordCounter: {
      container: "#counter",
      unit: "word",
    },
    // more options
  },
  disabled: false,
});
const modelDetails = ref([
  {
    id: 1,
    tab: "Project Details",
  },
  {
    id: 2,
    tab: "Project Estimate",
  },
  {
    id: 3,
    tab: "Invite Contractors",
  },
  {
    id: 4,
    tab: "Project Review",
  },
]);
const validation = ref({
  projectTitle: [
    (v: any) => {
      if (v) {
        return v?.length <= 255 || "Project title is too long..";
      } else {
        return true;
      }
    },
  ],
});
const projectSpecialisms = computed(
  () => store.getters[`${PROJECT_STORE}/projectSpecialisms`]
);

const ProjectForm = computed(
  () => store.getters[`${PROJECT_STORE}/ProjectForm`]
);

const historicalProjectForm = ref({
  projectType: null,
  specialisms: [],
  title: "",
  description: "",
  property: null,
  attachments: null,
  invitedContractors: [] as any[],

  projectEstimate: {
    estimateStartDate: null,
    estimates: [],
  },
});
const projectTypes = [
  {
    label: "Trade Verified",
    value: HistoricalProjectTypeEnum.TRADE_VERIFY,
  },
  {
    label: "DIY Project",
    value: HistoricalProjectTypeEnum.DIY_PROJECT,
  },
];
const filterProjectType = computed(() =>
  props.workflowType === HistoricalWorkFlowTypeEnum.CLIENT
    ? [
        {
          label: "Trade Verified",
          value: HistoricalProjectTypeEnum.TRADE_VERIFY,
        },
      ]
    : projectTypes
);
const projectAttachments = computed(
  () => store.getters[`${PROJECT_STORE}/projectAttachments`]
);
const isDisableNextButton = computed(() => {
  if (historicalProjectForm.value.title?.length >= 255) {
    return false;
  }
  return true;
});
const isFormValid = computed(() => {
  let isValid = false;
  if (
    historicalProjectForm.value.projectType &&
    historicalProjectForm.value.title &&
    historicalProjectForm.value.description &&
    projectAttachments.value.length &&
    isDisableNextButton.value
  ) {
    isValid = true;
  }
  if (screenType.value === TRADE_NETWORK_SCREEN) {
    if (
      !historicalProjectForm.value.invitedContractors.length &&
      historicalProjectForm.value.projectType ===
        HistoricalProjectTypeEnum.DIY_PROJECT
    ) {
      isValid = true;
    }
    // else if (
    //   !historicalProjectForm.value.invitedContractors.length &&
    //   historicalProjectForm.value.projectType ===
    //     HistoricalProjectTypeEnum.TRADE_VERIFY
    // ) {
    //   isValid = false;
    // }
  }

  return isValid;
});

const onClickToggleSpecialismForm = () => {
  isShowSpecialismModal.value = !isShowSpecialismModal.value;
};

const onClickToggleConfirmSave = () => {
  isConfirmSaveModal.value = !isConfirmSaveModal.value;
};

const onClickToggleUploadAttachmentUpload = () => {
  isShowProjectAttachmentUploadModal.value =
    !isShowProjectAttachmentUploadModal.value;
};

const onClickToggleConfirmContractorInviteModal = () => {
  isShowConfirmContractorInviteModal.value =
    !isShowConfirmContractorInviteModal.value;
};

const onClickTogglePostLoadingModal = () => {
  isPostLoadingModal.value = !isPostLoadingModal.value;
};

const onConfirmInvite = () => {
  if (screenType.value === TRADE_NETWORK_SCREEN) {
    screenType.value = PREVIEW_SCREEN;
    onClickToggleConfirmContractorInviteModal();
    return;
  }
};

const onEditorChange = ({
  quill,
  html,
  text,
}: {
  quill: any;
  html: string;
  text: string;
}) => {
  state._content = html;
};

const onBack = () => {
  if (screenType.value === DEFAULT) {
    currentSelectedTab.value = modelDetails.value[0];
    emit("on-back");
    return;
  }
  if (screenType.value === ESTIMATE_SCREEN) {
    currentSelectedTab.value = modelDetails.value[0];
    screenType.value = DEFAULT;
    return;
  }
  if (screenType.value === TRADE_NETWORK_SCREEN) {
    currentSelectedTab.value = modelDetails.value[1];
    screenType.value = ESTIMATE_SCREEN;
    historicalProjectForm.value.invitedContractors = [];
    return;
  }
  if (screenType.value === PREVIEW_SCREEN) {
    if (props.workflowType !== HistoricalWorkFlowTypeEnum.CLIENT) {
      historicalProjectForm.value.invitedContractors = [];
    }
    screenType.value = TRADE_NETWORK_SCREEN;
    currentSelectedTab.value = modelDetails.value[2];
    return;
  }
};

const formErrors = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getFromErrors`]
);

const currentSelectedTab = ref(modelDetails.value[0]);
const estimateErrors = computed(() => {
  return store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimateErrors`];
});
const customErrors = () => {
  const errors = estimateErrors.value;
  if (formErrors.value.startDate) {
    return ElMessage.error(`Project start Date required.`);
  }

  if (errors) {
    for (let key in errors) {
      if (key !== "subErrors") {
        const mainErrorObj = errors[key];
        for (let errorKey in mainErrorObj) {
          if (
            typeof mainErrorObj[errorKey] !== "object" &&
            mainErrorObj[errorKey]
          ) {
            ElMessage.error(`${mainErrorObj[errorKey]}`);
            return;
          }
        }
      }
    }

    for (let key in errors) {
      if (errors[key].subErrors) {
        const subErrors = errors[key].subErrors;
        for (let subKey in subErrors) {
          const subErrorObj = subErrors[subKey];
          for (let errorKey in subErrorObj) {
            if (subErrorObj[errorKey]) {
              return ElMessage.error(subErrorObj[errorKey]);
            }
          }
        }
      }
    }
  }
};
const onNext = async () => {
  if (!isFormValid.value) return;
  if (screenType.value === DEFAULT) {
    screenType.value = ESTIMATE_SCREEN;
    currentSelectedTab.value = modelDetails.value[1];

    await store.dispatch(`${JOB_TEMPLATE_STORE}/resetErrorFields`);
    return;
  }
  if (screenType.value === ESTIMATE_SCREEN) {
    const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`);
    if (isValid) {
      //save
      currentSelectedTab.value = modelDetails.value[2];
      screenType.value = TRADE_NETWORK_SCREEN;
      return;
    } else {
      customErrors();
    }
  }
  if (screenType.value === TRADE_NETWORK_SCREEN) {
    if (props.workflowType === HistoricalWorkFlowTypeEnum.CLIENT) {
      screenType.value = PREVIEW_SCREEN;
    } else {
      if (
        !historicalProjectForm.value.invitedContractors.length &&
        historicalProjectForm.value.projectType ===
          HistoricalProjectTypeEnum.DIY_PROJECT
      ) {
        screenType.value = PREVIEW_SCREEN;
        currentSelectedTab.value = modelDetails.value[3];
        return;
      }
      onClickToggleConfirmContractorInviteModal();
    }
    currentSelectedTab.value = modelDetails.value[3];

    return;
  }
};

const removeImageFromAttachments = (index: number) => {
  const attachments = projectAttachments.value;
  attachments.splice(index, 1);

  store.dispatch(`${PROJECT_STORE}/setProjectAttachments`, attachments);
};

const estimateForm = computed(() => {
  const estimates = store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`];

  return estimates;
});
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const customerTotal = computed(() => {
  const total: any =
    totalSubTotal.value + totalVat.value + serviceCharges.value;
  return Math.round(parseFloat(total) * 100) / 100;
});

const totalSubTotal = computed(() => {
  const sum = estimateForm.value.projectStages.reduce(
    (sum: any, phase: any) => {
      const roundedSubTotal = parseFloat(phase.subTotal);
      return sum + roundedSubTotal;
    },
    0
  );

  return Math.round(sum * 100) / 100;
});

const totalVat = computed(() => {
  const sum = estimateForm.value.projectStages.reduce(
    (sum: any, phase: any) => {
      const roundedTotalVat = parseFloat(phase.subTotal * phase.vat);
      return sum + roundedTotalVat;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee = estimateForm.value.projectStages.reduce(
    (sum: any, phase: any) => {
      const roundedTotalWithServiceFee = parseFloat(
        phase.subTotal * serviceFeePercentage
      );
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});
const projectEstimateEndDate = computed(() =>
  estimateForm.value.projectStages
    .filter((phase: any) => phase.stageType === "labour")
    .reduce((endDate: any, phase: any) => {
      return (endDate = moment(endDate).isAfter(moment(phase?.endDate))
        ? moment(endDate).format("YYYY-MM-DD")
        : moment(phase?.endDate).format("YYYY-MM-DD"));
    }, "")
);
const projectEstimatePayload = () => {
  let estimationPhaseData = [] as any;
  if (estimateForm.value) {
    estimateForm.value.projectStages.map((estimate: any, index: number) => {
      const estimateRecord = {
        phaseName: estimate.name,
        phaseType: estimate.stageType === "labour" ? 1 : 2,
        durationType: estimate.durationType,
        phaseStartDate: estimate.startDate,
        phaseEndDate: estimate.endDate,
        vat: estimate.vat,
        subTotal:
          typeof estimate.subTotal === "number"
            ? estimate.subTotal.toString()
            : estimate.subTotal,
        phaseOrder: estimate.phaseOrder,
        estimationPhaseTasks: [],
        estimationPhaseProperties: estimate.estimationPhaseProperties,
        color: estimate.color,
        user: { id: estimate?.user?.id },
        workStation: { id: estimate?.workStation?.id },
        estimatePhaseAttachment: estimate.attachments.map((data: any) => {
          return data.uploadedImages.id;
        }),
      };

      if (estimate?.projectStageTasks && estimate?.projectStageTasks?.length) {
        estimate.projectStageTasks.map((task: any) => {
          const estimateTask: any = {
            name: task.name,
            phaseTaskStartDate: task.startDate,
            phaseTaskEndDate: task.endDate,
            durationType: task.durationType,
            taskCost:
              typeof task.taskCost === "number"
                ? task.taskCost.toString()
                : task.taskCost,
            estimationPhaseTasksProperties: task.estimationPhaseTasksProperties,
            materialUnit: task.materialUnit?.toString(),
          } as any;

          estimateRecord.estimationPhaseTasks.push(estimateTask);
        });
      }
      estimationPhaseData.push(estimateRecord);
    });
  }
  const payload = {
    id: formData?.value.id,
    grantType: formData?.value.grantType,
    grantName: formData?.value.grantName,
    grantAmount: formData?.value.grantAmount,
    totalEstimateCost: customerTotal.value,
    suggestedStartDate: formData?.value.suggestedStartDate,
    estimationPhaseData,
  };
  return payload;
};

const onSaveProject = async () => {
  try {
    const filterInviteMembers =
      historicalProjectForm.value?.invitedContractors.map((member: any) => {
        return { email: member?.email, userId: member?.toUser?.id };
      });

    const projectSpecialismsIds = projectSpecialisms.value.map((data: any) => {
      return data.id;
    });

    const payload = {
      propertyId: propertyDetails.value?.localData.id,
      projectJobStatusId: 0,
      name: historicalProjectForm.value?.title,
      description: historicalProjectForm.value?.description,
      startDate: formData.value.suggestedStartDate,
      endDate: projectEstimateEndDate.value,
      historicalProjectType: historicalProjectForm.value?.projectType,
      inviteMembers: filterInviteMembers,
      attachments: historicalProjectForm.value?.attachments,
      projectEstimate: projectEstimatePayload(),
      categories: projectSpecialismsIds,
      workflowType: props.workflowType,
    };

    const formDataObj = new FormData();
    estimateForm.value?.projectStages?.map((stage: any) => {
      stage.attachments?.forEach((attachment: any, index: number) => {
        formDataObj.append(
          `${attachment.uploadedImages.id}`,
          attachment.uploadedImages.imageData
        );
      });
    });
    formDataObj.append("projectData", JSON.stringify(payload));
    await historicalProjectService.createHistoricalProject(formDataObj);

    onClickTogglePostLoadingModal();
  } catch (error) {
    console.log("error", error);
  } finally {
    onClickToggleConfirmSave();
    resetHistoricalData();
  }
};
const resetHistoricalData = () => {
  // empty Attachments
  store.dispatch(`${PROJECT_STORE}/setProjectAttachments`, []);
  // empty specialisms
  store.dispatch(`${PROJECT_STORE}/setProjectSpecialisms`, []);
  store.dispatch(`${JOB_TEMPLATE_STORE}/resetFormAndData`);
};
const onConfirmProjectType = (type: HistoricalProjectTypeEnum) => {
  if (type === HistoricalProjectTypeEnum.TRADE_VERIFY) {
    historicalProjectForm.value.projectType =
      HistoricalProjectTypeEnum.DIY_PROJECT as any;
  } else {
    historicalProjectForm.value.projectType =
      HistoricalProjectTypeEnum.TRADE_VERIFY as any;
  }
  onClickToggleConfirmContractorInviteModal();
};
const onCancelProjectType = () => {
  onConfirmInvite();
  historicalProjectForm.value.projectType =
    HistoricalProjectTypeEnum.DIY_PROJECT as any;
};
const onSaveSuccess = () => {
  onClickTogglePostLoadingModal();
  emit("on-back", true);
  emit("onHistoricProjectSaved");
};

const onSelectContractors = (selectedContractors: any) => {
  historicalProjectForm.value.invitedContractors = selectedContractors;
};
const propertyProjects = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyProjects`]
);
const propertyDetails = computed(
  () => store.getters[`${PROPERTY_STORE}/propertyDetails`]
);

const onClickEditDetailsSave = (details: any) => {
  if (details.title) {
    historicalProjectForm.value.title = details.title;
  }
  if (details.description) {
    historicalProjectForm.value.description = details.description;
  }
};
const specialismCategoryClass = (val: any) => {
  if (val.toLowerCase() === "krystal") {
    return "krystal";
  }
  if (val.toLowerCase() === "property") {
    return "property";
  }
  if (val.toLowerCase() === "trade") {
    return "trade";
  }
};

const onClickSaveRecord = (payload: any) => {
  historicalProjectForm.value.attachments = payload;
  onClickToggleConfirmSave();
};
onBeforeMount(() => {
  if (props.selectedClient)
    historicalProjectForm.value.invitedContractors = [props.selectedClient];

  resetHistoricalData();
});
</script>
<style lang="scss" scoped>
.text__editor {
  max-width: 100%;

  :deep(.v-field__field) {
    padding-bottom: 0.7rem;
  }

  :deep(.v-field__input) {
    font-size: 28px;
    color: #0c0f4a;
  }

  @include respond(xl) {
    width: 100%;
    padding: 0;
  }

  .custom_editor {
    :deep(.ql-toolbar) {
      border: none;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      box-shadow: 0px 0px 10px 0px #0000000d;
      background-color: rgba($white, 1);
      text-align: left;
      padding: 1.2rem;
      box-sizing: border-box;
      border-bottom: 1px solid rgba(12, 15, 74, 0.2);
    }

    .editor_ql {
      box-shadow: 0px 0px 10px 0px #0000000d;
      background-color: rgba($white, 1);
      border: none;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      height: 170px;

      @include respond(s720) {
        height: 140px;
      }

      :deep(.ql-editor) {
        font-size: 16px;
        color: rgba(12, 15, 74, 0.8);
        padding: 1.2rem 1.7rem;
      }

      :deep(.ql-editor.ql-blank::before) {
        left: 27px;
      }
    }
  }
}

.specilism-container {
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
}

.back_button {
  text-transform: capitalize !important;
  padding: 0;
  background-color: rgba($white, 1);
  border-radius: 50px;
  height: auto;

  &:hover {
    .overlay {
      opacity: 0;
    }
  }

  :deep(.v-btn__underlay) {
    background: rgba($white, 1);
  }

  :deep(.v-btn__prepend) {
    margin: 0;
  }

  :deep(.v-btn__content) {
    @include fluidFont(12, 12, 1.2);
    font-weight: 600;
    color: rgba($blueDark, 1);
    letter-spacing: 0.4px;
  }

  .v-ripple__container {
    display: none;
  }
}

.custom_input_field {
  width: 100%;
  max-width: 100%;

  :deep(.v-input__control) {
    .v-field {
      padding: 0;
      border-radius: 8px;
      background-color: rgba($white, 1);
      border: 1px solid rgba($buttonText, 0.4);

      .v-field__field {
        .v-field__input {
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(14, 14, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }

          input {
            @include fluidFont(14, 14, 1.2);
            font-weight: 400;
            letter-spacing: 0.4px;
            color: rgba($blueDark, 1);
            top: 13px;

            &::placeholder {
              @include fluidFont(14, 14, 1.2);
              font-weight: 400;
              letter-spacing: 0.4px;
              color: rgba($blueDark, 1);
              top: 13px;
            }
          }
        }
      }

      .v-field__outline {
        display: none;
      }
    }
  }
}

.button-tertiary {
  background-color: transparent !important;
  min-height: auto;
  max-height: fit-content;

  &:hover {
    .v-btn__overlay {
      opacity: 0;
    }
  }

  :deep(.v-ripple__container) {
    display: none !important;
  }
}

:deep(.v-btn):hover > .v-btn__overlay {
  opacity: 0;
}

.v-btn:hover > .v-btn__overlay {
  opacity: 0;
}

.specialism__wrapper {
  background-color: rgba($white, 1);
  border-radius: 15px;
  padding: 20px;
  margin-top: 15px;

  .header {
    margin: 0;
    padding: 0 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed rgba(#8687a5, 0.5);

    .title {
      width: calc(100% - 60px);
      max-width: 100%;

      h4 {
        @include fluidFont(25, 16, 1.2);
        font-weight: 600;
        color: rgba($blueDark, 1);
        letter-spacing: 0.64px;
        display: flex;
        gap: 8px;
        align-items: center;
      }
    }
  }
}

.specialism__wrapper__content {
  display: flex;
  width: 100%;
  gap: 16px;
  padding: 20px 0;
  flex-wrap: wrap;

  .specialism-card-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;

    .specialism-card-list__item {
      width: calc(25% - 12px);
      box-shadow: 0px 0px 8px 0px #0c0f4a24;
      padding: 8px;
      border-radius: 8px;
      position: relative;

      @include respond(s1024) {
        width: calc(33.33% - 11px);
      }

      @include respond(md) {
        width: calc(50% - 8px);
      }

      @include respond(md) {
        width: calc(50% - 8px);
      }

      @include respond(sm) {
        width: 100%;
      }

      .delete__icon {
        position: absolute;
        right: 0;
        z-index: 1;
        top: -10px;
        box-shadow: 0px 3.91px 19.56px 0px #2a2a2a1a;
        background-color: rgba($PrimaryRed, 1);
        border-radius: 100%;
        width: 25px;
        height: 25px;
        color: rgba($white, 1);
        @include fluidFont(18, 18, 1);
      }

      .specialism-card-list__item-author {
        .specialism-card-list__item-author-header {
          display: flex;
          justify-content: center;
          gap: 8px;
          align-items: center;
          background-color: rgba($orange, 1);
          border-radius: 8px;
          padding: 2px 8px;

          .speicalism__icon {
            color: rgba($white, 1);
            width: 12px;
            height: 12px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              filter: invert(100%) sepia(4%) saturate(7500%) hue-rotate(196deg)
                brightness(111%) contrast(99%);
            }
          }

          h6 {
            color: rgba($white, 1);
            @include fluidFont(12, 12, 1.2);
            font-weight: 700;
            text-transform: uppercase;
          }

          .v-icon {
            @include fluidFont(12, 12, 1.2);
            color: rgba($white, 1);
          }
        }

        .specialism-card-list__item-author-content {
          display: flex;
          gap: 8px;
          align-items: flex-start;
          margin-top: 4px;

          .category_icon {
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background-color: rgba($blueDark, 1);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              filter: invert(100%) sepia(4%) saturate(7500%) hue-rotate(196deg)
                brightness(111%) contrast(99%);
              width: 25%;
            }
          }

          .category_text {
            text-align: left;
            width: calc(100% - 60px);
            max-width: 100%;

            h5 {
              color: rgba($blueDark, 1);
              @include fluidFont(12, 12, 1.2);
              font-weight: 700;
              letter-spacing: 0.15px;
            }

            p {
              color: rgba($blueDark, 0.5);
              @include fluidFont(10, 10, 1.2);
              font-weight: 500;
              letter-spacing: 0.4px;
            }
          }
        }
      }
    }
  }
}
.hover-target {
  display: none;
}
.hover-trigger:hover .hover-target {
  display: block;
  img {
    filter: invert(100%) sepia(0%) saturate(5443%) hue-rotate(144deg)
      brightness(113%) contrast(100%);
    width: 10px;
    height: 10px;
  }
}
</style>
