<template>
  <WizardModal
    v-if="isShowSoleTraderWorkStationModal"
    :steps="tabListItems"
    :currentStep="getActiveSoleTraderWorkStationModal"
    @on-back="onClickBackButton"
    @on-close="onHandleClickClose"
    :hideBackButton="
      getActiveSoleTraderWorkStationModal >= 6 &&
      activeUserWorkstation.subscription
    "
  >
    <template #content>
      <main class="main tw-w-full tw-h-full tw-pt-2 rmd:tw-pt-4">
        <div class="custom__modal__content tw-h-full">
          <div
            class="custom__modal__content__wrapper !tw-h-full soletrader__content__wrapper tw-gap-4"
          >
            <div
              class="custom__modal__content__wrapper__top tw-h-full tw-flex tw-flex-col tw-justify-between rmd:tw-w-[50%]"
              v-if="currentSelectedTab.name === 'traderInfo'"
            >
              <div
                class="custom__modal__sub__content tw-flex tw-flex-col tw-gap-2 tw-w-full"
              >
                <label>Preferred / Trading As Name</label>
                <v-form :rules="validation" ref="tradingNameRef">
                  <v-text-field
                    :rules="validation.tradingName"
                    density="compact"
                    placeholder="E.g. Sparks Buildings"
                    v-model="tradingName"
                    variant="outlined"
                    class="c-input rounded-full"
                  ></v-text-field>
                </v-form>
              </div>
              <Button
                label="continue"
                @click="onClickContinue"
                :isActive="tradingName.length"
                class="button disable"
                :isLoading="isWorkStationCreated"
              />
            </div>

            <div
              class="custom__modal__content__wrapper__top tw-h-full tw-flex tw-flex-col tw-justify-between rmd:tw-w-[50%]"
              v-if="currentSelectedTab.name === 'profile'"
            >
              <div class="custom__modal__sub__content">
                <div
                  class="uploade-wrapper solo-trader-uploade-wrapper md:!tw-my-[25px] md:!tw-mx-[auto]"
                >
                  <div
                    class="uploade-wrapper md:!tw-my-[25px] md:!tw-mx-[auto] md:!tw-w-full md:!tw-h-full md:!tw-flex md:!tw-flex-col md:!tw-items-center md:!tw-justify-center"
                  >
                    <v-file-input
                      class="upload_file md:!tw-w-[150px] md:!tw-h-[150px]"
                      accept="image/png, image/jpeg"
                      append-inner-icon="mdi-image-plus"
                      variant="solo-filled"
                      @change="onFileChange"
                      @click:clear="clearImageUrl"
                      :class="{ 'added-img': imageUrl }"
                    ></v-file-input>
                    <v-img
                      class="uploaded-img md:!tw-w-[150px] md:!tw-h-[150px] md:!tw-left-[unset]"
                      :width="200"
                      aspect-ratio="16/9"
                      cover
                      :src="imageUrl"
                    ></v-img>
                    <Text
                      variant="p"
                      textAlign="center"
                      textColor="rgba(255, 0, 0,0.5)"
                      >{{ fileUploadErrorMsg }}</Text
                    ><br />
                    <Text
                      variant="span"
                      textAlign="center"
                      textColor="rgba(12, 15, 74, 0.5)"
                      whiteSpace="nowrap"
                      >Accepted file formats: PNG, JPEG</Text
                    >
                  </div>
                </div>
              </div>
              <Button
                label="continue"
                @click="onClickContinueSaveLogoAndName"
                :isActive="imageUrl.length"
                :isLoading="isWorkStationCreated"
              />
            </div>

            <div
              v-if="currentSelectedTab.name === 'subscription'"
              class="custom__modal__inner__content tw-overflow-y-auto tw-px-2 tw-box-border tw-pb-2"
            >
              <ul class="check-list">
                <li class="check-list__item">
                  Build trust with a Verified iknowa Trade Passport
                </li>
                <li class="check-list__item">
                  Enjoy 100MB of storage for documents and files
                </li>
                <li class="check-list__item">
                  Bid on unlimited jobs & win new clients
                </li>
                <li class="check-list__item">
                  Cancel your plan anytime if you're not satisfied
                </li>
                <li class="check-list__item">
                  After setting up your workstation, you can add members and pay
                  £9.99 per month/ user
                </li>
              </ul>
              <div class="plan-cards">
                <v-card class="plan-card">
                  <div class="plan-card__tags">
                    <span class="plan-card__tag-item">YEARLY</span>
                    <span class="plan-card__tag-item-discount"
                      >20% Discount</span
                    >
                  </div>
                  <p class="plan-card__price">
                    <span>£7.99</span> per month/ £95.90 today
                  </p>
                  <v-btn
                    class="button button-orange w-full"
                    :loading="yearlyPlanLoading"
                    @click="onClickPaymentPlan(PaymentPlanType.YEARLY)"
                    >Choose YEARLY</v-btn
                  >
                </v-card>
                <v-card class="plan-card">
                  <div class="plan-card__tags">
                    <span class="plan-card__tag-item">MONTHLY</span>
                  </div>

                  <p class="plan-card__price"><span>£9.99</span> per month</p>
                  <v-btn
                    class="button button-orange w-full"
                    :loading="monthlyPlanLoading"
                    @click="onClickPaymentPlan(PaymentPlanType.MONTHLY)"
                    >Choose MONTHLY</v-btn
                  >
                </v-card>
              </div>
            </div>
            <!-- Free plan -->
            <!-- <div
              v-if="currentSelectedTab.name === 'subscription'"
              class="custom__modal__inner__content tw-overflow-y-auto tw-px-2 tw-box-border tw-pb-2"
            >
              <ul class="check-list tw-gap-2">
                <li class="check-list__item">Access to exclusive features</li>
                <li class="check-list__item">Good user experience</li>
                <li class="check-list__item">Priority customer support</li>
                <li class="check-list__item">New features and updates</li>
              </ul>
              <v-btn
                class="button button-orange tw-w-auto"
                @click="onClickFreePlan"
                >Start My Free Trial</v-btn
              >
            </div> -->
            <!-- <div
              class="custom__modal__inner__content tw-overflow-y-auto"
              v-if="currentSelectedTab?.name === 'Address'"
            >
              <div class="modal__inner__body">
                <v-form
                  ref="addressFormRef"
                  :rules="addressRule"
                  @submit.prevent
                >
                  <div v-if="isShowAddressData">
                    <div class="custom__modal__sub__content">
                      <label>Address line</label>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        class="c-input rounded-full"
                        v-model="addressForm.address"
                        :rules="addressRule.address"
                        :disabled="!isChangeManualAddress"
                      ></v-text-field>
                    </div>
                    <div class="custom__modal__sub__content">
                      <label>City / Town</label>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        class="c-input rounded-full"
                        v-model="addressForm.place"
                        :rules="addressRule.place"
                        :disabled="!isChangeManualAddress"
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="custom__modal__sub__content postcode__wrapper">
                    <label>Postcode</label>
                    <v-text-field
                      density="compact"
                      placeholder="e.g E1 1BA"
                      variant="outlined"
                      class="c-input rounded-full tw-mb-2"
                      v-model="addressForm.postCode"
                      :rules="addressRule.postCode"
                      :disabled="!isChangeManualAddress && isShowAddressData"
                    ></v-text-field>
                    <ul
                      v-if="
                        addressListFromPostcode?.length && !isShowAddressData
                      "
                      class="address__postcodes"
                    >
                      <li
                        v-for="{ DPA } in addressListFromPostcode"
                        :key="DPA?.UPRN"
                        :class="{ 'is-active': selectedUPRN === DPA?.UPRN }"
                        @click="onSelectedAddress(DPA)"
                      >
                        <div>{{ DPA?.ADDRESS }}</div>
                      </li>
                    </ul>
                  </div>
                  <span class="error_msg" v-if="errorMessagePostcode">{{
                    errorMessagePostcode
                  }}</span>
                  <v-btn
                    v-if="!isShowAddressData && !addressListFromPostcode.length"
                    class="button disabled"
                    :class="{ 'button-orange': isDisabledNextButton }"
                    :loading="isActiveNextButton"
                    :disabled="!isDisabledNextButton"
                    @click.enter="onClickFindAddress"
                    size="large"
                    block
                  >
                    {{ selectedDPA ? "continue" : "find address" }}
                  </v-btn>
                  <v-btn
                    v-if="isShowAddressData"
                    class="button disabled"
                    :class="{ 'button-orange': isDisabledNextButton }"
                    size="large"
                    block
                    :disabled="!isDisabledNextButton"
                    :loading="loadingContinue"
                    @click.enter="onClickAddressContinue"
                  >
                    CONTINUE
                  </v-btn>
                  <div v-if="!isShowAddressData" class="onboarding-not-service">
                    <p>
                      Cannot find address?
                      <span @click="showManualForm"
                        >Enter Manually <v-icon icon="mdi-chevron-right"
                      /></span>
                    </p>
                  </div>
                  <div v-if="isShowAddressData" class="onboarding-not-service">
                    <p>
                      Address Not Correct?
                      <span @click="changeAddress"
                        >Change Address <v-icon icon="mdi-chevron-right"
                      /></span>
                    </p>
                  </div>
                </v-form>
              </div>
            </div>
            <div
              class="sole-trader-data-wrapper rmd:tw-w-[50%]"
              v-if="currentSelectedTab.name === 'idCheck'"
            >
              <div class="your-img-wrap">
                <img
                  class="open-logo"
                  src="../../../../assets/icons/id-check.svg"
                />
              </div>
              <v-btn
                class="button button-orange w-full"
                v-if="isShowNextButton"
                @click="goToNextTab"
                >Next</v-btn
              >
              <a class="btn_link" :href="siltRedirectUrl"
                >Start ID verification</a
              >
            </div> -->
            <TermsAndCondition
              v-if="currentSelectedTab.name === 'termCondition'"
            />
            <div
              class="tw-flex tw-flex-col tw-gap-3"
              v-if="currentSelectedTab.name === 'termCondition'"
            >
              <!-- <Text class="rmd:!tw-text-lg" variant="h6"
                >General Platform Usage</Text
              > -->
              <div>
                <v-checkbox
                  v-model="acceptAggreement"
                  label="I accept & agree to the Terms of Service"
                ></v-checkbox>
                <Button
                  class="rmd:!tw-w-[400px]"
                  label="continue"
                  @click="goToNextTab"
                  :isActive="acceptAggreement"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </template>
  </WizardModal>
  <commonDialog
    v-model="isShowSuccessfullModal"
    :isShowHeader="false"
    :isShowBackButton="false"
    width="465px"
    max-width="100%"
    height="auto"
    persistent
    :className="['solo-trader__last__step__box', 'workstation_create_modal']"
  >
    <template v-slot:body>
      <div class="tw-flex tw-flex-col tw-gap-4 rmd:tw-gap-10">
        <div>
          <div class="header-title">
            Great job,
            <span class="tw-ml-2"> {{ activeUserWorkstation?.name }}.</span>
          </div>
          <div class="header-sub-title">
            You have successfully created your Trade Workstation.
          </div>
        </div>
        <div class="custom__modal__img">
          <img
            src="../../../../assets/images/ws-creation-success-image.png"
            alt="Create Workstation"
          />
        </div>
        <Text variant="h6" textWeight="400" textColor="rgba(12, 15, 74, 0.5)"
          >Time to take your business sky high and supercharge your skills!
          Start by completing your Trade Passport.</Text
        >
      </div>
      <v-btn
        class="button button-orange !tw-mt-[16px] rmd:!tw-mt-[24px]"
        size="large"
        block
        @click="onClickSoloTraderDashboard"
      >
        continue 🚀
      </v-btn>
    </template>
  </commonDialog>
  <PaymentStatusModal
    v-if="statusModal.PAYMENT === currentStatusModalName"
    :onContinue="onClickStatusModalContinue"
    :paymentStatus="currentModalStatus"
    :planType="planType"
  />
  <KYCStatusModal
    v-if="statusModal.KYC === currentStatusModalName"
    :onContinue="onClickTermAndConditionNext"
    :kycStatus="currentModalStatus"
  />
</template>

<style lang="scss" scoped>
@import "@/modules/workstation/soletrader/styles/SoleTraderWorkStationComponent.scss";
</style>

<script lang="ts">
import { computed, onBeforeMount, ref, watch } from "vue";
import {
  VUE_APP_STRIPE_PUBLISHABLE_KEY,
  VUE_APP_SILT_KYC_COMPANY_APP_ID,
  VUE_APP_OS_POSTCODE_URL,
  VUE_APP_OS_API,
} from "@/config";
import { useStore } from "vuex";

import $axios from "@/core/utils/axios-api-config";

import { USER_STORE } from "@/store/modules/user";
import { cloneObject } from "@/core/utils/common";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  BusinessDetailTypeEnum,
  PaymentPlanType,
  UserWorkstationVerificationType,
} from "@/core/enums/RolesEnum";
import PaymentStatusModal from "@/core/components/PaymentStatusModal.vue";
import KYCStatusModal from "@/core/components/KYCStatusModal.vue";
import axios from "axios";
import CommonDialog from "@/core/components/CommonDialog.vue";

import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import CheckBlue from "@/core/components/icons/CheckBlue.vue";

import WizardModal from "@/core/components/modals/WizardModal.vue";
import TermsAndCondition from "@/core/components/common/TermsAndCondition.vue";
import userService from "@/core/services/user.service";

export default {
  components: {
    PaymentStatusModal,
    KYCStatusModal,
    CommonDialog,
    Button,
    Text,
    // CheckBlue,
    WizardModal,
    TermsAndCondition,
  },
  setup(props: any, ctx: any) {
    const store = useStore();
    const isShowSoleTraderWorkStationModal = ref(true);
    const statusModal = { PAYMENT: "payment", KYC: "kyc" };
    const checkoutRef = ref(null);
    const tradingName = ref("");
    const currentStatusModalName = ref("");
    const loading = ref(false);
    const user = computed(() => store.getters[`${USER_STORE}/user`]);
    const customer = ref("") as any;
    const imageUrl = ref("");
    let imageData = ref("");
    const fileUploadErrorMsg = ref("");

    const yearlyPlanLoading = ref(false);
    const monthlyPlanLoading = ref(false);
    const isWorkStationCreated = ref(false);

    const createdWorkstation = ref(null) as any;
    const isShowSuccessfullModal = ref(false);

    const acceptAggreement = ref(false);

    const stripe = ref(null);
    const planType = ref() as any;
    const sessionId = ref(null);
    const redirectUrl = ref("");
    const isShowNextButton = ref(false);
    const currentModalStatus = ref("");
    const currentStep = ref(1);
    const currentSelectedTab = ref(null) as any;
    const tabListItems = [
      {
        id: 1,
        tab: "Trader Info",
        name: "traderInfo",
        header: "So, you're a sole trader...",
        title: "What should we call your business?",
        description: "Please provide your Preferred Trading name",
        hideAction: true,
      },
      {
        id: 2,
        tab: "Profile",
        name: "profile",
        header: "Sole Trader Logo",
        title: "Upload profile picture",
        description:
          "Please upload a logo that represents your business or an image that clearly shows your face.",
        hideAction: true,
      },
      {
        id: 3,
        tab: "Terms & Conditions",
        name: "termCondition",
        title: "Terms & Conditions",
        header: "Terms & Conditions",
        description:
          "Please read through and agree to the terms & conditions to access your iknowa platform.",
        hideAction: true,
      },
      {
        id: 4,
        tab: "Subscription",
        name: "subscription",
        header: "Choose a plan that works for you",
        title: "Choose Your Workstation Subscription Plan",
        description:
          "Grow your business - Get quality jobs, manage your operations, and take advantage of subsidised up-skilling courses.",
        hideAction: true,
      },
      // {
      //   id: 5,
      //   tab: "Address",
      //   name: "Address",
      //   header: "Where is your physical HQ?",
      //   title: "Please give the best address for your business?",
      //   description:
      //     "This is usually the address you receive business-related correspondence to!",
      //   hideAction: true,
      // },
      // {
      //   id: 6,
      //   tab: "ID Check",
      //   name: "idCheck",
      //   header: "ID Check",
      //   title: "We need to verify your identity",
      //   description:
      //     "We will require your passport or driver’s license. Verified ID is required to ensure trust, security, and compliance on the Iknowa platform.",
      //   hideAction: true,
      // },
    ];

    const tradingNameRef = ref(null);
    const validation = ref({
      tradingName: [(v: any) => !!v || "Workstation name is required"],
    });
    const getActiveSoleTraderWorkStationModal: any = computed(
      () => store.getters[`${WORKSTATION}/getActiveSoleTraderWorkStationModal`]
    );

    const onClickBackButton = () => {
      const currentTabIndex = tabListItems.findIndex(
        (tab) => tab.name === currentSelectedTab.value.name
      );
      if (currentTabIndex === 0) {
        ctx.emit("onClickCloseTab");
        return;
      }
      if (currentTabIndex === 4) {
        currentStep.value = currentTabIndex;
        currentStatusModalName.value = statusModal.PAYMENT;
        isShowSoleTraderWorkStationModal.value = false;

        currentSelectedTab.value = tabListItems[currentStep.value];
        currentModalStatus.value = activeUserWorkstation.value.subscription
          ? "paid"
          : "failed";
        return;
      }
      currentSelectedTab.value = tabListItems[currentStep.value - 1];
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        activeSoleTraderWorkStationModal: currentStep.value,
      });
    };
    const goToNextTab = () => {
      const currentTabIndex = tabListItems.findIndex(
        (tab) => tab.name === currentSelectedTab.value.name
      );
      currentStep.value = currentTabIndex + 1;
      if (currentStep.value < tabListItems.length) {
        currentSelectedTab.value = tabListItems[currentStep.value];
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          activeSoleTraderWorkStationModal: currentStep.value + 1,
        });
      }
    };

    const onClickContinue = async () => {
      goToNextTab();
    };

    const onHandleClickClose = () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        activeSoleTraderWorkStationModal: 1,
        isShowWorkStationModal: false,
        isShowSoleTraderWorkStation: false,
        createdWorkstationId: null,
      });
    };

    const profileImageAttachment = ref(null);
    const onClickContinueSaveLogoAndName = async () => {
      try {
        if (imageData.value) {
          isWorkStationCreated.value = true;
          const formData = new FormData();
          formData.append("file", imageData.value);
          const response = await store.dispatch(
            `${USER_STORE}/uploadProfileImage`,
            formData
          );
          profileImageAttachment.value = response?.attachment;
        }
        goToNextTab();
      } catch (error: any) {
        console.log("");
        fileUploadErrorMsg.value = error?.response?.data?.message;
      } finally {
        isWorkStationCreated.value = false;
      }
    };

    const onClickPaymentPlan = async (planName: number) => {
      try {
        if (planName === PaymentPlanType.YEARLY) {
          yearlyPlanLoading.value = true;
        } else {
          monthlyPlanLoading.value = true;
        }
        if (!tradingName.value.length) return;
        const companyName = tradingName.value;
        const type = BusinessDetailTypeEnum.SOLETRADER;

        isWorkStationCreated.value = true;

        await store.dispatch(`${USER_STORE}/updateUser`, {
          userId: user.value?.id,
          userData: { hasBusinessType: true },
        });
        isWorkStationCreated.value = true;

        createdWorkstation.value = await store.dispatch(
          `${WORKSTATION}/createUserWorkstation`,
          {
            userId: user.value?.id,
            userWorkstation: {
              name: companyName,
              companyName,
              businessType: type,
              workstationAccountType: type,
            },
          }
        );
        createdWorkstation.value = await store.dispatch(
          `${WORKSTATION}/updateUserWorkstation`,
          {
            userId: user.value?.id,
            userWorkstationId: createdWorkstation.value.id,
            payload: {
              profileImage: profileImageAttachment.value || null,
            },
          }
        );

        await store.dispatch(
          `${WORKSTATION}/getUserWorkstations`,
          user.value?.id
        );

        await store.dispatch(`${USER_STORE}/initializeUserState`);

        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
        planType.value = planName;
        if (planName === PaymentPlanType.YEARLY) {
          initializeStripePayment(false, false, 95.9);
        } else if (planName === PaymentPlanType.MONTHLY) {
          initializeStripePayment(false, false, 9.99);
        }
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          createdWorkstationId: createdWorkstation.value.id,
        });
      } catch (error) {
        console.log("error while adding workstation", error);
      } finally {
        yearlyPlanLoading.value = false;
        monthlyPlanLoading.value = false;
        isWorkStationCreated.value = false;
      }
    };
    const initializeStripe = () => {
      let stripe = null;

      if ("Stripe" in window) {
        const { Stripe } = window as any;

        // eslint-disable-next-line no-new
        stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
      }

      return stripe;
    };

    const initializeStripePayment = async (
      isTrial: boolean,
      oneTimePayment: boolean,
      price: number
    ) => {
      stripe.value = initializeStripe();

      if (stripe.value) {
        await onCheckout(isTrial, oneTimePayment, price);
      }
    };

    const onCheckout = async (
      isTrial: boolean,
      oneTimePayment: boolean,
      price: number
    ) => {
      await createSession(isTrial, oneTimePayment, price);
      if (sessionId.value) {
        (stripe.value as any)
          .redirectToCheckout({
            sessionId: sessionId.value,
          })
          .then((result: any) => {
            if (result.error) {
              console.log();
            }
          });
      }
    };

    const onClickFreePlan = async () => {
      await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
        userId: user.value?.id,
        userWorkstationId: createdWorkstation.value?.id,
      });
      await store.dispatch(`${WORKSTATION}/getUserWorkstations`, user.value.id);
      await store.dispatch(`${USER_STORE}/initializeUserState`);
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      isShowSoleTraderWorkStationModal.value = false;
      isShowSuccessfullModal.value = true;
    };

    onBeforeMount(async () => {
      getCheckoutUserKYC();
      getCheckoutSession();

      currentSelectedTab.value =
        tabListItems[
          typeof getActiveSoleTraderWorkStationModal.value === "number"
            ? getActiveSoleTraderWorkStationModal.value - 1
            : 0
        ];

      if (
        currentSelectedTab.value.name === "logo" &&
        !tradingName.value.length
      ) {
        onClickBackButton();
      }
    });

    const getCheckoutSession = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params?.session_id) {
          const ws_modalData: any = window.localStorage.getItem("ws_modalData");
          if (JSON.parse(ws_modalData)?.createdWorkstationId) {
            userService.deleteWorkStationData(
              user.value?.id,
              JSON.parse(ws_modalData)?.createdWorkstationId
            );
            store.dispatch(`${WORKSTATION}/setWsModal`, {
              activeSoleTraderWorkStationModal: 1,
              isShowSoleTraderWorkStation: false,
              isShowWorkStationModal: true,
              createdWorkstationId: null,
            });
          }
          return;
        }
        store.dispatch(`${WORKSTATION}/setWsModal`, {
          createdWorkstationId: null,
        });
        currentStatusModalName.value = statusModal.PAYMENT;
        isShowSoleTraderWorkStationModal.value = false;
        const url = `/payment/${user.value?.id}/session?sessionId=${params.session_id}&isWorkstation=1`;

        const response: any = await $axios.get(url);

        if (response?.status === 400) {
          currentModalStatus.value = "failed";
          return;
        }
        if (response.payment_status !== "paid") {
          await userService.deleteWorkStationData(
            user.value?.id,
            response.metadata.userWorkstationId
          );
          return;
        }
        currentModalStatus.value = response?.payment_status;
        planType.value = parseInt(response.metadata?.planType, 10);

        console.log(response, "resonseee");

        await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
          userId: user.value?.id,
          userWorkstationId: response.metadata?.userWorkstationId,
        });
        store.commit(`${USER_STORE}/setCertificates`, []);
        store.commit(`${USER_STORE}/setInsurances`, []);
        store.commit(`${USER_STORE}/setUserSpecialisms`, []);
        await store.dispatch(
          `${WORKSTATION}/getUserWorkstations`,
          user.value.id
        );
        await store.dispatch(`${USER_STORE}/initializeUserState`);
        await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      } catch (error) {
        console.log();
      }
    };
    const getCheckoutUserKYC = async () => {
      try {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        if (!params?.silt_user_id) return;
        const soloTraderVerificationData = await store.dispatch(
          `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
          {
            id: params.silt_user_id,
            verificationType: UserWorkstationVerificationType.KYC,
          }
        );
        await store.dispatch(
          `${WORKSTATION}/saveWorkstationVerificationData`,
          soloTraderVerificationData
        );
        currentStatusModalName.value = statusModal.KYC;
        isShowSoleTraderWorkStationModal.value = false;
        currentModalStatus.value = soloTraderVerificationData?.status;
        await store.dispatch(`${USER_STORE}/initializeUserState`);
      } catch (error: any) {
        if (error?.response?.status === 400) {
          currentStatusModalName.value = statusModal.KYC;
          isShowSoleTraderWorkStationModal.value = false;
          currentModalStatus.value = "ERROR";
          return;
        }
      }
    };

    const createSession = async (
      isTrial = false,
      oneTimePayment = false,
      price: number
    ) => {
      const user = cloneObject(store.getters[`${USER_STORE}/user`]);
      const { id: userId, userStripeCustomer, email } = user;

      const productName = "tradesperson-profile";

      const entity = JSON.stringify({
        id: userId,
        name: "tradesperson-profile",
      });

      if (!userStripeCustomer?.length) {
        await $axios
          .post(`/payment/${userId}/customer`, {
            email,
            entity,
          })
          .then(async (customerResponse) => {
            await store.dispatch(`${USER_STORE}/saveUserStripePayment`, [
              customerResponse,
            ]);
            customer.value = customerResponse;
          })
          .catch((e) => {
            console.log();
          });
      } else {
        const [stripeCustomer] = userStripeCustomer;
        customer.value = stripeCustomer;
      }

      const host = `${window.location.protocol}//${window.location.host}`;
      redirectUrl.value = `${host}/dashboard`;

      const { customerId } = customer.value;
      const totalPrice = price;

      if (customerId) {
        await $axios
          .post(`/payment/${userId}/session`, {
            customerId,
            price: totalPrice,
            redirectUrl: redirectUrl.value,
            productName,
            metadata: JSON.stringify({
              userWorkstationId: createdWorkstation.value?.id,
              name: createdWorkstation.value?.name,
              companyName: createdWorkstation.value?.name,
              productName,
              userId,
              businessType: BusinessDetailTypeEnum.SOLETRADER,
              planType: planType.value,
              workstationAccountType: BusinessDetailTypeEnum.SOLETRADER,
              capacity: "100MB",
              totalMember: 1,
            }),
            isTrial,
            oneTimePayment,
          })
          .then(async (sessionResponse: any) => {
            if (sessionResponse) {
              sessionId.value = sessionResponse?.id;
            }
          })
          .catch((error) => {
            console.log();
          });
      }
    };
    const activeUserWorkstation = computed(
      () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
    );

    const onClickStatusModalContinue = async () => {
      try {
        const url = new URL(window.location.href);
        url.search = "";
        if (currentStatusModalName.value === statusModal.PAYMENT) {
          const response = await store.dispatch(
            `${WORKSTATION}/getWorkstationVerificationData`,
            activeUserWorkstation.value?.id
          );
        }
        history.replaceState(null, "", url.toString());
        // isShowSoleTraderWorkStationModal.value = true;
        // goToNextTab();
        isShowSoleTraderWorkStationModal.value = false;
        isShowSuccessfullModal.value = true;
      } catch (error) {
        console.log();
      }
    };

    const isProfileVerifiable = computed(
      () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
    );

    const siltRedirectUrl = computed(() => {
      const host = `${window.location.protocol}//${window.location.host}`;
      redirectUrl.value = `${host}/dashboard`;
      return `https://signup.getsilt.com/?company_app_id=${VUE_APP_SILT_KYC_COMPANY_APP_ID}&hide_redirect_button=true&customer_user_id=${user.value?.id}-${activeUserWorkstation.value?.id}&redirect_url=${redirectUrl.value}`;
    });

    const onClickTermAndConditionNext = () => {
      isShowSoleTraderWorkStationModal.value = false;
      isShowSuccessfullModal.value = true;
    };

    const onClickSoloTraderDashboard = async () => {
      store.dispatch(`${WORKSTATION}/setWsModal`, {
        activeSoleTraderWorkStationModal: 1,
        isShowWorkStationModal: false,
        isShowSoleTraderWorkStation: false,
      });
      await store.dispatch(`${WORKSTATION}/setActiveUserWorkstation`);
    };

    //address model
    const selectedUPRN = ref("");
    const selectedDPA = ref(null) as any;
    const isManualForm = ref(false);
    const isShowAddressData = ref(false);
    const addressFormRef = ref(null) as any;
    const addressForm = ref({
      postCode: "",
      address: "",
      place: "",
      latitude: "",
      longitude: "",
    }) as any;
    const cloneAddressForm = ref();
    const billingAddressForm = ref(null);
    const addressListFromPostcode = ref([]) as any;
    const errorMessagePostcode = ref("");
    const isActiveNextButton = ref(false);
    const isChangeManualAddress = ref(false);
    const loadingContinue = ref(false);
    const isDisabledNextButton = computed(() =>
      /^[a-zA-Z0-9\s]+$/.test(addressForm.value.postCode)
    );
    const addressRule = ref({
      address: [(v: string) => !!v || "Address is required"],
      place: [(v: string) => !!v || "City/Town is required"],
      postCode: [(v: string) => !!v || "PostCode is required"],
    });
    const onSelectedAddress = (DPA: any) => {
      selectedUPRN.value = DPA?.UPRN;
      selectedDPA.value = DPA;
      addressForm.value.address = removePostCodeAndPostTownInFullAddress(
        DPA.POSTCODE,
        DPA.POST_TOWN,
        DPA.ADDRESS
      );
      addressForm.value.place = DPA.POST_TOWN;
      addressForm.value.postCode = DPA.POSTCODE;
      addressForm.value.latitude = DPA.LAT;
      addressForm.value.longitude = DPA.LNG;
      billingAddressForm.value = DPA;
      if (billingAddressForm.value) {
        isShowAddressData.value = true;
      }
    };

    const removePostCodeAndPostTownInFullAddress = (
      postcode: string,
      postTown: string,
      address: string
    ) => {
      if (!postcode && !address && !postTown) {
        console.log("Please enter a word to remove and the address.");
        return;
      }

      const concatRemovedWord = `, ${postTown}, ${postcode}`;

      // Escape special characters in the word to be removed
      const escapedWord = concatRemovedWord.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );

      // Create a regular expression to match the word to be removed
      const regex = new RegExp(`\\b${escapedWord}\\b`, "gi");

      // Remove the word from the input string
      return address.replace(regex, "").trim();
    };
    const onClickFindAddress = async () => {
      const isFormValid = await addressFormRef.value.validate();
      if (!isFormValid.valid) return;
      if (billingAddressForm.value) {
        isShowAddressData.value = true;
      } else {
        try {
          isActiveNextButton.value = true;
          const response = await userService.fetchAddressFromPostCode(
            addressForm.value.postCode
          );
          addressListFromPostcode.value = response.data.results;
          if (response.status === 200) {
            errorMessagePostcode.value = "";
            addressForm.value.address =
              response.data?.result?.primary_care_trust;
            addressForm.value.place = response.data?.result?.region;
          } else if (response.status === 400) {
            errorMessagePostcode.value = "Invalid postcode";
            console.error("Failed to fetch address data");
          }
        } catch (error) {
          errorMessagePostcode.value = "Invalid postcode";
          console.error("Error fetching address data:", error);
        } finally {
          isActiveNextButton.value = false;
        }
      }
    };
    const onClickAddressContinue = async () => {
      const isFormValid = await addressFormRef.value.validate();
      if (!isFormValid.valid) return;
      loadingContinue.value = true;
      try {
        // const isAddressChange = isChangeManualAddress.value
        //   ? !Object.keys(addressForm.value).every(
        //       (key) => addressForm.value[key] === cloneAddressForm.value[key]
        //     )
        //   : false;

        if (isManualForm.value) {
          await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
            userId: user.value.id,
            formData: {
              residencyAddressLookup: "",
              residencyAddressLine: addressForm.value.address,
              residencyTown: addressForm.value.place,
              residencyPostcode: addressForm.value.postCode,
              type: user.value?.userBusinessDetails?.type,
              userVerificationStatus:
                user.value?.userBusinessDetails?.userVerificationStatus,
            },
          });
        } else {
          if (!selectedDPA.value) return;
          await store.dispatch(`${USER_STORE}/saveBusinessDetails`, {
            userId: user.value.id,
            formData: {
              residencyAddressLookup: selectedDPA.value?.ADDRESS,
              residencyAddressLine: addressForm.value.address,
              residencyTown: addressForm.value.place,
              residencyPostcode: addressForm.value.postCode,
              latitude: addressForm.value.latitude.toString(),
              longitude: addressForm.value.longitude.toString(),
              type: user.value?.userBusinessDetails?.type,
              userVerificationStatus:
                user.value?.userBusinessDetails?.userVerificationStatus,
            },
          });
        }

        store.dispatch(`${USER_STORE}/initializeUserState`);
        goToNextTab();
      } catch (error) {
        console.log("error:", error);
      } finally {
        loadingContinue.value = false;
        selectedDPA.value = null;
        selectedUPRN.value = "";
      }
    };

    watch(
      () => addressForm.value.postCode,
      (newValue) => {
        if (newValue === null || newValue === "") {
          addressListFromPostcode.value = [];
        }
      },
      { deep: true, immediate: true }
    );

    const showManualForm = () => {
      isChangeManualAddress.value = true;
      isShowAddressData.value = true;
      isManualForm.value = true;
      resetForm();
    };

    const changeAddress = () => {
      isShowAddressData.value = false;
      isManualForm.value = false;
      selectedDPA.value = null;
      selectedUPRN.value = "";
      resetForm();
    };

    const resetForm = () => {
      addressForm.value.address = "";
      addressForm.value.place = "";
      addressForm.value.postCode = "";
      addressForm.value.latitude = "";
      addressForm.value.longitude = "";
      billingAddressForm.value = null;
    };

    const onFileChange = async (event: any) => {
      fileUploadErrorMsg.value = "";
      const fileInput = event.target;
      if (fileInput.files && fileInput.files.length > 0) {
        const file = fileInput.files[0];
        if (
          file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "image/jpg"
        ) {
          imageUrl.value = URL.createObjectURL(file);
          imageData.value = file;
        }
      } else {
        console.error("Error: No file selected.");
      }
    };

    const clearImageUrl = () => {
      imageUrl.value = "";
    };
    return {
      isShowSoleTraderWorkStationModal,
      onClickContinue,
      tradingName,
      VUE_APP_STRIPE_PUBLISHABLE_KEY,
      loading,
      checkoutRef,
      onClickPaymentPlan,
      PaymentPlanType,
      tabListItems,
      currentSelectedTab,
      onClickBackButton,
      goToNextTab,
      isShowNextButton,
      onClickStatusModalContinue,
      currentModalStatus,
      isProfileVerifiable,
      siltRedirectUrl,
      currentStatusModalName,
      statusModal,
      onClickTermAndConditionNext,
      addressRule,
      isShowAddressData,
      addressForm,
      addressListFromPostcode,
      onSelectedAddress,
      errorMessagePostcode,
      isDisabledNextButton,
      isActiveNextButton,
      onClickFindAddress,
      addressFormRef,
      loadingContinue,
      onClickAddressContinue,
      changeAddress,
      isChangeManualAddress,
      isShowSuccessfullModal,
      activeUserWorkstation,
      onClickSoloTraderDashboard,
      imageUrl,
      onFileChange,
      clearImageUrl,
      isWorkStationCreated,
      onClickContinueSaveLogoAndName,
      validation,
      tradingNameRef,
      showManualForm,
      selectedUPRN,
      acceptAggreement,
      planType,
      onClickFreePlan,
      currentStep,
      getActiveSoleTraderWorkStationModal,
      onHandleClickClose,
      selectedDPA,
      yearlyPlanLoading,
      monthlyPlanLoading,
      fileUploadErrorMsg,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/modules/workstation/soletrader/styles/SoleTraderWorkStationComponent.scss";

:deep(.v-stepper-item) {
  width: 100%;
  max-width: 25px;
}

:deep(.v-stepper-item__content) {
  display: none;
}

:deep(.v-stepper-item--selected) {
  max-width: unset;
  .v-stepper-item__content {
    display: inline-block;
  }
}

@media screen and (min-width: 568px) {
  :deep(.v-stepper-item) {
    max-width: unset;
  }
  :deep(.v-stepper-item__content) {
    display: inline-block;
  }
}
.address {
  flex-basis: auto;
  margin-top: 26px;
  h2 {
    color: #0c0f4a;
    margin-bottom: 32px;
  }
  p {
    cursor: pointer;
  }

  .el-button {
    margin-top: 2rem;
  }

  &__input-manually {
    .el-input {
      margin-top: 1rem;
    }
  }

  &__postcodes {
    max-height: 245px;
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    overflow-y: auto;
    list-style: none;
    background-color: rgba($blueDark, 0.05);
    border-radius: 16px;
    padding: 12px;
    margin: 12px 0;
    // box-shadow: 0 0px 12px rgba(42, 42, 42, 0.1);

    li {
      color: rgba($blueDark, 1);
      @include fluidFont(14, 14, 1);
      text-align: left;
      padding: 4px 12px;
      border-radius: 30px !important;
      font-weight: 400;
      line-height: 25px;
      cursor: pointer;

      &:hover {
        background-color: rgba($orange, 1);
        color: rgba($white, 1);
        border-radius: 20px;
      }
    }

    li.is-active {
      background-color: rgba(255, 165, 0, 0.6);
      color: #fff;
      border-radius: 20px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.modal_wrapper__content {
  height: calc(100dvh - 500px);
  margin-top: 24px;
  overflow: auto;
}

.onboarding-not-service {
  margin-top: 30px;
  @include fluidFont(14, 14, 1);
  font-weight: 500;
  color: rgba($blueDark, 0.5);
  letter-spacing: 1px;

  span {
    font-weight: 700;
    color: #ffa500;
    letter-spacing: 0.25px;
    cursor: pointer;
  }
}
</style>
